@import (reference) '../../../core/vars';
@import (reference) '../../../core/colors';

.push-menu,
.flat-menu,
.big-menu {

	.level {
		background: @marine;
		top: 55px;
		height: ~'calc(100vh - 55px)';
	}

	li {
		background: @marine;
		border-bottom: 1px solid rgba(255,255,255,0.1);
		transition: background 300ms;
		
		&:hover {
			background: shade(@marine, 20%);
		}
	}

	.deeper {
		.gfx(triangle-thin-right, after);
	}

	a {
		position: relative;
		display: block;
		color: white;
		font-family: @light;
		font-size: 17px;
		text-decoration: none;
		padding: 1em;

		&:hover {
			color: white;
		}

		&.back {
			font-family: @regular;
			font-size: @font-size;
			background: rgba(255,255,255,0.1);
		}

		&.deeper:after,
		& .deeper {
			position: absolute;
			top: 50%;
			height: 100%;
			right: 0;
			padding: 1em;
			width: 55px;
			text-align: center;
			border-left: solid 1px rgba(255, 255, 255, 0.1);
			transform: translateY(-50%);
	
			.orange & { 
				border-left-color: rgba(0, 0, 0, 0.2);
			}
		}

		& .deeper {
			&:after {
				position: relative;
				padding: 0;
			}
		}
	}
}