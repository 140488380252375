.flat-menu {

	// Reset container padding, it is not needed here
	padding-right: 0;
	padding-left: 0;

	.level {
		width: 100%;

		// Overwrite inline styles from drop-down-menu.js trying to
		// help displaying the menu in view but does it all wrong.
		left: 0 !important;

		&:not(.default) {
			position: absolute;
			visibility: hidden;
			opacity: 0;

			// No legacy styles needed, on IE < 10 the
			// .levels just snap in and pop out without
			// transition
			transform: scale(0.95) translate(0, 10%);
			transition: transform 300ms, opacity 300ms;
			
			margin-top: -1px;
			z-index: 3;
		}

	}

	.back {
		display: none;
	}

	.hover {
		> .level {			
			opacity: 1;
			transform: scale(1) translate(0, 0);
		}
	}

	.visible {
		> .level { visibility: visible; }
	}

	ul {
		.nav-ul(true);
		.container();

		// Reset container padding, it is not
		// needed here
		padding-right: 0;
		padding-left: 0;
	}
}