@import (reference) '../../core/vars';
@import (reference) '../../core/colors';
@import (reference) '../../core/mixins';

.filter {

	@media @mobile {
		background: white;

		h4 {
			background: @marine;
		}

		h6 {
			display: none;
		}

		form {
			padding: 1em;
		}

		.sub-menu--content {
			position: relative;
		}

		[data-accordion-content] {
		}
		[data-accordion-state="closed"][data-accordion-content] {
			.sub-menu--content {
			}
			form {
			}
		}
	}

	@media @min-laptop {
		.inner();

		> [data-accordion-content] {
			height: auto !important;
		}
	}

	@media @min-tablet and @max-tablet {
		//padding-bottom: 1em;
		border-bottom: 1px solid @grey-dark;

	}
}

.filter--blank-slate {
	width: 100% !important;
	padding: 2em 0;
	color: @grey-dark;
	font-size: 1.6em;
	text-align: center;

	* + & {
		display: none;
	}
}

.filter--no-filter {
	[data-accordion-state^="open"] & {
		display: none;
	}
}

.filter--apply-filter {
	display: none;
	
	[data-accordion-state^="open"] & {
		display: inline;
	}
}