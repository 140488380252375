@import (reference) '../../../core/vars';
@import (reference) '../../../core/colors';

.meta-menu {
	margin-left: 7px;
	font-size: 15px;
}

.meta-menu--toggler {
	background: @grey;
	transition: background 200ms;

	&:hover {
		background: darken(@grey, 5%);
	}

	span {
		text-transform: uppercase;
		font-family: @bold;
		margin-left: 17px;
	}

	.gfx {
		position: absolute;
		font-size: 0.7em;
	}
}

.meta-menu--content {
	.no-rgba & { background: @grey-light; }
	background: fade(@grey-light, 95%);
	z-index: 1;

	&:extend(.clearfix all);

	&:after {
		content: '';
		position: absolute;
		background: @grey;
		top: 0;
		right: 0;
		bottom: 0;
		left: 80%;

		@media @min-full-hd {
			left: 68%;
		}
	}

	> .container {
		position: relative;
		z-index: 1;
	}

	// Styles for flexbox
	.flexbox &, .flexboxlegacy &{
		> nav > ul {
			display: flex;
			justify-content: space-between;
		}
	}

	// Styles for no flexbox
	.no-flexbox.no-flexboxlegacy &{
		> nav > ul {
			.clearfix();

			> li {
				float: left;
				width: 25%;
			}
		}
	}

	.first-level {
		padding: 1em;

		@media @min-laptop {
			padding: 2em;
		}

		> a {
			font-family: @semibold;
			font-size: 17px;
			text-decoration: none;
			margin-bottom: 0.5em;
		}

		> nav {
			// Overwrite inline accordion styles
			height: auto !important;
		}

		&.meta-menu--contact {
			background: @grey;
			overflow: hidden;
			margin-bottom: -99em;
			padding-bottom: 100em;

			nav {

				a {
					display: block;
					position: relative;
					padding-left: 1.5em;
					font-family: @semibold;

					&:before {
						position: absolute;
						left: 0;
					}
				}

				li {
					margin-top: 0.5em;

					&:first-child { margin-top: 0; }
				}
			}
		}
	}

}
