@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

.social-links {
	ul {
		list-style: none;
		padding-left: 0;
		&:extend(.clearfix all);
	}

	li {
		float: left;
		margin-left: 0.75em;

		&:before {
			content: none;
		}

		&:first-child {
			margin-left: 0;
		}
	}
}

.social-link {
	display: block;
	&:extend(.clearfix all);

	i, span, img {
		float: left;
	}

	i, img {
		color: white;
		height: 30px;
		width: 30px;
		line-height: 30px;
		text-align: center;
		margin-right: 1px;

		.facebook & {
			background: @facebook;
		}

		.twitter & {
			background: @twitter;
		}

		.google-plus & {
			background: @google-plus;
		}

		.linkedin & {
			background: @linkedin;
		}

		.maps & {
			height: 24px;
			width: 24px;
			margin: 3px;
		}

		@media @mini-mobile {
			height: 48px;
			width: 48px;
			line-height: 48px;
			font-size: 1.5em;
		}
	}

	span {
		display: block;
		color: white;
		line-height: 30px;
		padding: 0 0.75em;
		transition: background 300ms;

		.maps & {
			color: @anthracite;
		}

		.facebook & {
			background: @facebook-light;

			&:hover {
				background: @facebook;
			}
		}

		.twitter & {
			background: @twitter-light;

			&:hover {
				background: @twitter;
			}
		}

		.google-plus & {
			background: @google-plus-light;

			&:hover {
				background: @google-plus;
			}
		}

		.linkedin & {
			background: @linkedin-light;

			&:hover {
				background: @linkedin;
			}
		}

		.linkedin & {
			background: @linkedin-light;

			&:hover {
				background: @linkedin;
			}
		}

		@media @mini-mobile {
			display: none;
		}
	}

}