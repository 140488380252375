@import (reference) '../../../core/vars';

@media @desktop {
	@import 'service-menu-desktop';
}
@media @mobile {
	@import 'service-menu-mobile';
}
/* .lt-ie9 {
	@import 'service-menu-desktop';
} */