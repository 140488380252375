.agenda--time {
	float: left;
	margin-right: 1.5em;

	span {
		display: block;
		color: @ochre;
		text-align: center;
	}

	.day {
		font-size: 24px;
		font-family: @bold;
		line-height: 1em;
	}

	.month {
		text-transform: uppercase;
	}
}