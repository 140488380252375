@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

.faq {
	padding-left: 0;
	list-style: none;

	li {
		&:before {
			content: none;
		}
	}

	h4 {
		border-bottom: 1px solid @grey;

		a {
			position: relative;
			display: block;
			padding: 0.5em 2.5em 0.5em 0;

			.gfx(triangle-thin-down, after);

			&:after {
				position: absolute;
				right: 1em;
				color: @grey-dark;
				transition: transform 300ms;

			}
			&[data-accordion-state^='open']:after {
				transform: scaleY(-1);
			}
		}
	}
}