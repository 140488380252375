// Abstracted Bootstrap grid with IE8 fallback (.lt-ie9{})

.row {
  &:extend(.clearfix all);
}

// lte IE8 Stuff
.lt-ie9 {
 body { min-width: 992px; }
 .container { min-width: 970px; max-width: 1170px; }

 .row > {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 { float: left; }

  .col-sm-12 { width: 100%; }
  .col-sm-11 { width: 91.66666667%; }
  .col-sm-10 { width: 83.33333333%; }
  .col-sm-9  { width: 75%; }
  .col-sm-8  { width: 66.66666667%; }
  .col-sm-7  { width: 58.33333333%; }
  .col-sm-6  { width: 50%; }
  .col-sm-5  { width: 41.66666667%; }
  .col-sm-4  { width: 33.33333333%; }
  .col-sm-3  { width: 25%; }
  .col-sm-2  { width: 16.66666667%; }
  .col-sm-1  { width: 8.33333333%; }

  .col-md-12 { width: 100%; }
  .col-md-11 { width: 91.66666667%; }
  .col-md-10 { width: 83.33333333%; }
  .col-md-9  { width: 75%; }
  .col-md-8  { width: 66.66666667%; }
  .col-md-7  { width: 58.33333333%; }
  .col-md-6  { width: 50%; }
  .col-md-5  { width: 41.66666667%; }
  .col-md-4  { width: 33.33333333%; }
  .col-md-3  { width: 25%; }
  .col-md-2  { width: 16.66666667%; }
  .col-md-1  { width: 8.33333333%; }

  .col-lg-12 { width: 100%; }
  .col-lg-11 { width: 91.66666667%; }
  .col-lg-10 { width: 83.33333333%; }
  .col-lg-9  { width: 75%; }
  .col-lg-8  { width: 66.66666667%; }
  .col-lg-7  { width: 58.33333333%; }
  .col-lg-6  { width: 50%; }
  .col-lg-5  { width: 41.66666667%; }
  .col-lg-4  { width: 33.33333333%; }
  .col-lg-3  { width: 25%; }
  .col-lg-2  { width: 16.66666667%; }
  .col-lg-1  { width: 8.33333333%; }
 }
}