@import (reference) '../../core/colors';

// Small triangle on bottom of the square
.square {
	.square-triangle {
		&:before, &:after {
			border-width: 9px 0;
			transition: border 200ms;
		}
	}

	&[data-accordion-toggler] {
		position: relative;
	}

	&[data-accordion-toggler]:hover {
		.square-triangle:after {
			border-top-color: @grey-light;
		}
	}

	&[data-accordion-state^='open'] .square-triangle {
		&:before, &:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -11px;
			border: 0 solid transparent;
			border-width: 9px 11px;
			z-index: 1;
		}

		&:before {
			border-top-color: @marine;
			.marine & { border-top-color: @marine; }
			.orange & { border-top-color: @orange; }
			.green & { border-top-color: @green; }
			.red & { border-top-color: @red; }
			.purple & { border-top-color: @purple; }
			.sky & { border-top-color: @sky; }
			.ochre-light & { border-top-color: @ochre-light; }
		}

		&:after {
			margin-top: -3px;
			border-top-color: white;
		}
	}
}