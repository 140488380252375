@import (reference) '../../../core/colors';
@import (reference) '../../../core/mixins';
@import (reference) '../../../core/vars';

#footer {

	> .container {
		padding-top: 2em;
		padding-bottom: 2em;

		> .row {
			overflow: hidden;
		}
	}

	.footer-menu {
		h6 {
			margin-top: 2.5em;

			&:first-child { margin-top: 0; }
		}

		@media @max-tablet {
			display: none;
		}
	}
	.footer-menu--contact {
		@media @min-laptop {
			border-left: 1px solid white;
			padding-bottom: 2000px;
			margin-bottom: -2000px;
		}

		ul {
			margin-top: 2.5em;

			@media @max-tablet {
				margin-top: 0;
			}
		}

		li {
			margin-top: 0.5em;
			
			@media @max-tablet {
				margin-top: 1em;
			}
		}
	}

	.footer-menu--meta {
		.footer--social-links {
			float: right;

			@media @min-laptop {
				width: 25%;
			}
		}
	}
}