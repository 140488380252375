.thumbnail-slider--slide {
	position: relative;
}

.thumbnail-slider--text {
	position: absolute;
	top: auto; right: 0; bottom: 0; left: 0;
	padding: 0.25em 2.5em 0.25em 0.5em;
	color: white;
	background: rgba(0,0,0,0.7);
}

.thumbnail-slider--pagination {
	position: absolute;
	right: 0.5em;
	bottom: 0.25em;
}

.thumbnail-slider--thumbnails {
	margin-right: 30px;
	margin-left: 30px;

	img {
		margin-right: 0.5em;
		margin-left: 0.5em;
	}

	.slick-prev, .slick-next {
		opacity: 1;
		background: none;
		color: @marine;
	}

	.slick-prev {
		right: 100%;
		left: auto;
		.gfx(arrow-left);
	}

	.slick-next {
		left: 100%;
		right: auto;
		.gfx(arrow-right);
	}
}