.flat-menu {
	li > a {
		display: block;
		font-size: 17px;
		padding: 0.5em 1em;
	}
	
	// All levels
	.level {
	
		// Make hovered parent level visible
		&.default .hover a {
			transition: background 300ms;
			background: darken(white, 5%);
		}
		
		// Second and third level
		.level {
			border-top: 1px solid white;
			.branded-background(true, false);

			.marine & { border-top-color: darken(@marine, 10%); }
			.red & { border-top-color: darken(@red, 10%); }
			.green & { border-top-color: darken(@green, 10%); }
			.purple & { border-top-color: darken(@purple, 10%); }
			.anthracite & { border-top-color: darken(@anthracite, 10%); }
			.orange & { border-top-color: darken(@orange, 10%); }
			.sky & { border-top-color: darken(@sky, 10%); }
			
			// Indicate a deeper level
			.deeper {
				.gfx(triangle-thin-down, after);

				&:after {
					margin-left: 0.5em;
					font-size: 0.65em;
				}
			}

			// Show darker color on hover
			a {
				transition: background 300ms;
				.branded-background(true, true);
			}
	
			// Keep hover status when not actually
			// hovering over this element
			.hover > a {
				.dark-branded-background();
			}
		}
	}
}

.flat-menu > .level {
	margin-left: -15px;

	a {
		text-decoration: none;
	}

	& > ul {
		.nav-ul(true);

		& > li {
			& > a {
				display: block;
				font-size: 17px;
				padding: 0.5em 1em;

				// Border bottom for active first level links
				position: relative;

				&:before, &:after {
					content: '';
					position: absolute;
					bottom: 0;
					top: auto;
					width: 0;
					height: 2px;
					transition: width 300ms;

					.branded-background();
				}
				&:before { left: 50%; }
				&:after { right: 50%; }

				&:hover:before, &:hover:after {
					width: 50%;
				}
			}

			// Activate border bottom
			&.hover > a,
			&.active > a {
				&:before, &:after {
					width: 50%;
				}
			}

			&:first-child{
				padding-left: 15px;

				& > a {
					padding-left: 0;
				}
			}
		}
		
	}
}