@import (reference) '../core/colors';

// Map background colors to font colors
// For every string, a variable like @grey-light must
// be defined (see colors.less)
.map(~'grey-light', @anthracite);
.map(~'grey', @anthracite);
.map(~'marine', white);
.map(~'orange', @anthracite);
.map(~'green', white);
.map(~'red', white);
.map(~'purple', white);
.map(~'sky', white);
.map(~'ochre-light', @anthracite);
.map(~'ochre', @anthracite);
.map(~'white', @anthracite);
.map(~'anthracite', white);
.map(~'fog', @anthracite);
.map(~'accent', white);
.map(~'none',white);
.map(~'ami', white);
.map(~'agef', white);
.map(~'baurecht', @anthracite);
.map(~'aref', white);
.map(~'fmentoring', white);
.map(~'regard', white);

// Nice to have
.map(~'facebook',white);
.map(~'twitter',white);
.map(~'google-plus',white);
.map(~'linkedin',white);

// Nice transition on hover
.hover {
	transition: background 200ms;
}

// Branded color mappings

// Default
.bg-branded { background-color: @marine; color: white; }
.color-branded { color: @marine; }

.brand(~'grey-light', @anthracite);
.brand(~'grey', @anthracite);
.brand(~'marine', white);
.brand(~'orange', @anthracite);
.brand(~'green', white);
.brand(~'red', white);
.brand(~'purple', white);
.brand(~'sky', white);
.brand(~'ochre-light', @anthracite);
.brand(~'white', @anthracite);
.brand(~'anthracite', white);
.brand(~'accent', white);
.brand(~'ami', white);
.brand(~'agef', white);
.brand(~'baurecht', @anthracite);
.brand(~'aref', white);
.brand(~'fmentoring', white);
.brand(~'regard', white);