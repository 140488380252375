.icons {
	list-style: none;
	padding-left: 0;
	&:extend(.clearfix all);

	li {
		float: left;
		padding: 0.5em;

		&:before {
			content: none;
		}
	}

	i {
		font-size: 30px;
		display: block;
		text-align: center;
	}

	span {
		margin-top: 0.5em;
		font-size: 12px;
		display: block;
		text-align: center;
	}
}