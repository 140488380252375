@import (reference) '../../core/vars';



a {
	//
	text-decoration: none;
	
	color: inherit;
	&:hover, &:focus {
		text-decoration: none;
		color: inherit;
	}

	
	// Style external links (only in the main
	// section of the page)
	.bg-branded &, .bg-red &,
	.bg-sky &, .bg-orange &,
	.bg-green &, .bg-purple &,
	.bg-marine &, .bg-anthracite,
	.bg-accent &,&{
		color: inherit;
		&:hover{
			color: inherit;
		}
	}
}

p > a {
	color: @sky;
	&:hover, &:focus {		
		color: @sky;
	}
}

.colorparagraph{
	color: inherit;
	text-decoration: underline;
	&:hover{
		color: inherit;
		text-decoration: underline;
	}

	&.link{
		text-decoration:  none;

		&:hover{
			text-decoration: none;
		}
	}
}
.bg-branded p a{
	.colorparagraph;
}
.bg-red p a{
	.colorparagraph;
}
.bg-sky p a{
	.colorparagraph;
}
.bg-orange p a{
	.colorparagraph;
}
.bg-green p a{
	.colorparagraph;
}
.bg-purple p a{
	.colorparagraph;
}
.bg-marine p a{
	.colorparagraph;
}
.bg-anthracite p a{
	.colorparagraph;
}
.bg-accent p a{
	.colorparagraph;
}

.link {
	display: block;
	position: relative;
	font-family: @semibold;
	color: @sky;
	&:hover{
		color: @sky;
	}

	text-decoration:  none;

	&:hover{
		text-decoration: none;
	}

	/*div > a&{
		color: inherit;
		&:hover{
			color: inherit;
		}
	}
	article > a&{
		color: inherit;
		&:hover{
			color: inherit;
		}
	}*/

	&:before {
		position: absolute;
		left: 0;
	}

	&.after {
		&:after {
			position: absolute;
			right: 0;
			display: inline-block;		/*	This line fix a graphics problem in Firefox */
		}
	}

	&.gfx {
		padding-left: 1.5em;

		&.after {
			padding-left: 0;
			padding-right: 1.5em;
		}
	}

	&.inline {
		display: inline;
		//font-family: inherit;		
	}

	_:-moz-tree-row(hover), &.inline {
		display: inline-block;
		//font-family: inherit;		
	}

	&.deeper{
		color: inherit;
	}

	&.external{
		padding-left: 1.5em;
		.gfx(external);
	}




	&.download {
		padding-left: 1.5em;
		.gfx(download);
	}
	footer &{
		color: @white;
		&:hover{
			color: @white;
		}
	}

	.banner--text & {
		color: inherit;
		&:hover{
			color: inherit;
		}

	}

	li &{
		font-family: inherit;
	}
	li&{
		list-style: none;
		font-family: inherit;
	}


	li&:before {
		/* For a round bullet */
		content: '\2022';
		/* For a square bullet */
		/*content:'\25A0';*/
		display: block;
		position: relative;
		max-width: 0;
		max-height: 0;
		left: -17px; //left: -24px;
		top: 0;
		color: @sky;
		font-size: 15px;
	}

	a&{
		font-family: @semibold;
	}

}

/*
Small hack to solve display issue of the right arrow on Firefox (23.11.2017)
Should be used like this: <a class="link-inline gfx gfx-arrow-right after" href="#">My text</a>
Instead of the current: <a class="inline link gfx gfx-arrow-right after" href="#">My text</a>
The fact that .link displays the link as a block causes the problem of the misplaced gfx-right-arrow on Firefox.
*/
.link-inline {
	display: inline;
	font-family: @semibold;
	color: @sky;
	&:hover{
		color: @sky;
	}

	text-decoration:  none;

	&:hover{
		text-decoration: none;
	}

	&:before {
		display: inline-block;
		padding-right: 0.5em;
	}

	&.after {
		&:after {
			display: inline-block;
			padding-left: 0.5em;
		}
	}

	&.gfx {
		padding-left: 0;

		&.after {
			padding-left: 0;
			padding-right: 0.5em;
		}
	}

	a&{
		font-family: @semibold;
	}
}

a.inline-fine{
	display: inline;
	font-family: inherit!important;
	color: @sky;
	&:hover, &:focus {
		color: @sky;
	}
}



