
// Only apply this styles if modernizr.js detected
// a 3d capable browser. We assume that every 3d
// capable browser is also ready for transitioning
.csstransforms3d {
	.pusher {

		// Set default translate, otherwise
		// transition would not work properly
		transition: transform 500ms;
	}

	body.off {

		// Make fixed header stick to new coordinate
		// system and prevent it from flickering as
		// well.
		// Obviously does not work in any IE
		#wrapper {
			transform: translate3d(0,0,0);
		}

		.pusher {
			
			// Set offcanvas for mobiles to cover
			// the entire viewport
			transform: translate3d(100%, 0, 0);
		}
	}

	.offcanvas {
		transition: transform 500ms;
	
		// Move offcanvas to the left
		transform: translate3d(-100%, 0, 0);
	}
}