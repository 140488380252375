.aside--teaser {
	list-style: none;
	padding-left: 0;
	margin-right: -15px;
	margin-left: -15px;

	&:extend(.clearfix all);
	
	> li {
		margin-top: 15px;
		padding-right: 15px;
		padding-left: 15px;

		&:before {
			content: none;
		}

		&:first-child {
			margin-top: 0;

			 > article {
			 	padding-top: 0;
			 	border-top: none;
			 }
		}
		
		> article {
			padding-top: 15px;
			border-top: 1px solid @grey-dark;
		}

		@media @mobile and @min-mobile {
			float: left;
			width: 50%;
			
			// First two elements no margin-top
			&:first-child + li {
				margin-top: 0;

				> article {
					padding-top: 0;
					border-top: none;
				}
			}
		}
	}
}