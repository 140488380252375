@import (reference) '../../../core/vars';
@import (reference) '../../../core/header';


// Animations with fallback
@import 'push-menu-3d';
@import 'push-menu-legacy';

.push-menu, .flat-menu, .big-menu {
	position: absolute;
	top: @mobile-header-height;
	bottom: 0;
	width: 100%;
	padding: 0;

	.level {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		overflow: auto;
		z-index: 1;
		-webkit-overflow-scrolling: touch;

		// Make underlying level darker to 
		// visually distinguish it from the
		// level sliding in
		&:before {
			content: '';
			visibility: hidden;
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background: rgba(0,0,0,0.4);
			opacity: 0;
			transition: opacity 500ms;
			transition-delay: 100ms;
		}
		
		&.display-cover {
			&:before {
				visibility: visible;
			}
		}
		&.cover {
			&:before {
				transition: opacity 500ms;
				transition-delay: 0;
				opacity: 1;
			}
		}

		ul {
			list-style: none;
			padding-left: 0;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
}