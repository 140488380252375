@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

.pagination {
	text-align: center;

	ul {
		display: inline-block;
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
		&:extend(.clearfix all);
	}

	li {
		float: left;
		
		&:before {
			content: none;
		}
	}

	a {
		display: block;
		padding: 0 0.5em;
		color: @grey-dark;
		font-family: @semibold;
	}

	.pagination--next a, .pagination--prev a, .active a {
		color: @marine;
	}

	.disabled a {
		cursor: default;
		color: @grey-dark;
	}

	.pagination--next a {
		padding-right: 0;
		.gfx(arrow-right, after);
		&:after {
			font-size: 0.75em;
			margin-left: 0.5em;
		}
	}

	.pagination--prev a {
		padding-left: 0;
		.gfx(arrow-left);
		&:before {
			font-size: 0.75em;
			margin-right: 0.5em;
		}
	}
}