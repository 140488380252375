// Add a special selector for big-menu second
// levels, they have different html structure
.csstransforms3d {
	.big-menu {
		.level {
			&.in > .container > .row > .col-sm-6 > .row > .level2 > ul > li > a {
				transform: translate3d(0,0,0);
			}
		}
	}
}

.level {
	&.cover {
		overflow: hidden;
	}
}

.level2 {
	padding-right: 0;
	padding-left: 0;
}

.big-menu {
	.row .col-sm-6 {
		position: static;
	}
}

.level3 {
	&.in {
		z-index: 3;
	}
}