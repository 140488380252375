@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

.banner--text {
	position: absolute;
	
	//right: 3em;
	max-width: 358px;

	@media @min-tablet{
		top: 50%;
		left:50%;
	}


	@media @min-laptop{
		transform: translate(25%, -50%);
	}
	@media @min-desktop{
		width: 433px;
		max-width: 433px;		
	}

	.content &{
		width:50%;
		right: 1em;
		left:auto;		
		transform: none;
		top:50%;
		transform:translateY(-50%);
		@media @mobile {
			top:auto;
			width: 100%;
			max-width: none;
			left: auto;
			right:auto;
			transform:none;
		}
		

	}

	padding: 1em;
	//background: fade(@marine, 90%); 
	color: white;
	

	@media @mobile {
		top: auto;
		bottom: 0;
		left:0;
		right: auto;
		width: 100%;
		max-width: none;
		transform: translate(0,0);

	}

	.banner--image & {
		@media @mini-mobile {
			position: relative;
		}
	}

	.anthracite & { background: fade(@anthracite, 90%); }
	.marine & { background: fade(@marine, 90%); }
	.orange & { background: fade(@orange, 90%); color: @anthracite; }
	.green & { background: fade(@green, 90%); }
	.red & { background: fade(@red, 90%); }
	.purple & { background: fade(@purple, 90%); }
	.sky & { background: fade(@sky, 90%); }
	.ami & { background: fade(@ami, 90%); }
	.agef & { background: fade(@agef, 90%); }
	.baurecht & { background: fade(@baurecht, 90%); color: @anthracite; }
	.aref & { background: fade(@aref, 90%); }
	.fmentoring & { background: fade(@fmentoring, 90%); }
	.regard & { background: fade(@regard, 90%); }

	&.bg-fog { background: @fog !important; color: @anthracite !important; }
	&.bg-none { background: none !important; color: @white !important; text-shadow: 0 0 3px @anthracite, 0 0 5px @anthracite; }

	&:extend(.inner all);
}
