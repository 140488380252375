@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

.filter--list {
	padding-left: 0;

	> li {
		margin-left: 30px;
		display: block;
		border-top: none;
		border-bottom: 1px solid @grey-dark;
		&:before { content: none; }
		&:first-child {
			border-top: 1px solid @grey-dark;
		}

		> label {
			display: block;
			cursor: pointer;

			> input[type='checkbox'],
			> input[type='radio'] {
				position: absolute;
				clip: rect(0 0 0 0);
				opacity: 0;
			}

			> span {
				position: relative;
				display: block;
				padding: 0.5em 2em 0.5em 0.5em;
				color: @anthracite;
				background: white;
				transition: background 300ms;

				&:after {
					position: absolute;
					right: 0.5em;
					color: @marine;
				}

			}

			> input:checked + span {
				background: @grey;
				.gfx(check, after);
			}
			
			> input[type='checkbox']:checked + span:hover {
				
				// Don't display this on mobile, it is confusing
				// because hover is mapped to focus on some
				// devices.
				@media @desktop {
					.gfx(close, after);
				}
			}
		}
	}
}