body {
	&.show-fake-scrollbar {

		// Prevent page from jumping around
		// when offcanvas is closing by displaying
		// scrollbar on body.
		overflow-y: scroll;

		#wrapper {
			overflow: hidden;
			height: 100%;
		}
	}
}