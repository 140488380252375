@font-face {
  font-family: "fonticons";
  src: url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.eot');
  src: url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.eot?#iefix') format('eot'),
    url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.woff') format('woff'),
    url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.ttf') format('truetype'),
    url('//cdn.unifr.ch/uf/v2.4.0/fonts/fonticons.svg#fonticons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-styling(){
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-family: "fonticons";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  speak: none;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  vertical-align: top;
  line-height: inherit;
}

.gfx{
  &:before, &.after:after{
    .font-styling();
  }
  &.after {
    &:before{display: none;}
  }
}

.gfx(@class, @element: before){
  & when(@element = before){
    &:before{
      .glyphmap();
    }
  }
  
  & when(@element = after){
    &:after{
      .glyphmap();
    }
  }

  .glyphmap(){
    .font-styling();
    & when (@class = arrow-right){ content: "\E001" }
    & when (@class = arrow-up){ content: "\E002" }
    & when (@class = arrow-left){ content: "\E003" }
    & when (@class = arrow-down){ content: "\E004" }
    & when (@class = close){ content: "\E005" }
    & when (@class = directions){ content: "\E006" }
    & when (@class = facebook){ content: "\E007" }
    & when (@class = home){ content: "\E008" }
    & when (@class = lock){ content: "\E009" }
    & when (@class = logo){ content: "\E00A" }
    & when (@class = mail){ content: "\E00B" }
    & when (@class = pin){ content: "\E00C" }
    & when (@class = tiles){ content: "\E00D" }
    & when (@class = triangle-thin-down){ content: "\E00E" }
    & when (@class = triangle-thin-left){ content: "\E00F" }
    & when (@class = triangle-thin-right){ content: "\E010" }
    & when (@class = triangle-thin-up){ content: "\E011" }
    & when (@class = twitter){ content: "\E012" }
    & when (@class = user){ content: "\E013" }
    & when (@class = search){ content: "\E014" }
    & when (@class = triangle-right){ content: "\E015" }
    & when (@class = triangle-up){ content: "\E016" }
    & when (@class = triangle-left){ content: "\E017" }
    & when (@class = triangle-down){ content: "\E018" }
    & when (@class = hamburger){ content: "\E019" }
    & when (@class = external){ content: "\E01A" }
    & when (@class = download){ content: "\E01B" }
    & when (@class = upload){ content: "\E01C" }
    & when (@class = file){ content: "\E01D" }
    & when (@class = check){ content: "\E01E" }
    & when (@class = dot){ content: "\E01F" }
    & when (@class = google-plus){ content: "\E020" }
    & when (@class = linkedin){ content: "\E021" }
    
  }
}

.gfx-arrow-right:before { content: "\E001" }
.gfx-arrow-up:before { content: "\E002" }
.gfx-arrow-left:before { content: "\E003" }
.gfx-arrow-down:before { content: "\E004" }
.gfx-close:before { content: "\E005" }
.gfx-directions:before { content: "\E006" }
.gfx-facebook:before { content: "\E007" }
.gfx-home:before { content: "\E008" }
.gfx-lock:before { content: "\E009" }
.gfx-logo:before { content: "\E00A" }
.gfx-mail:before { content: "\E00B" }
.gfx-pin:before { content: "\E00C" }
.gfx-tiles:before { content: "\E00D" }
.gfx-triangle-thin-down:before { content: "\E00E" }
.gfx-triangle-thin-left:before { content: "\E00F" }
.gfx-triangle-thin-right:before { content: "\E010" }
.gfx-triangle-thin-up:before { content: "\E011" }
.gfx-twitter:before { content: "\E012" }
.gfx-user:before { content: "\E013" }
.gfx-search:before { content: "\E014" }
.gfx-triangle-right:before { content: "\E015" }
.gfx-triangle-up:before { content: "\E016" }
.gfx-triangle-left:before { content: "\E017" }
.gfx-triangle-down:before { content: "\E018" }
.gfx-hamburger:before { content: "\E019" }
.gfx-external:before { content: "\E01A" }
.gfx-download:before { content: "\E01B" }
.gfx-upload:before { content: "\E01C" }
.gfx-file:before { content: "\E01D" }
.gfx-check:before { content: "\E01E" }
.gfx-dot:before { content: "\E01F" }
.gfx-google-plus:before { content: "\E020" }
.gfx-linkedin:before { content: "\E021" }


.after.gfx-arrow-right:after { content: "\E001" }
.after.gfx-arrow-up:after { content: "\E002" }
.after.gfx-arrow-left:after { content: "\E003" }
.after.gfx-arrow-down:after { content: "\E004" }
.after.gfx-close:after { content: "\E005" }
.after.gfx-directions:after { content: "\E006" }
.after.gfx-facebook:after { content: "\E007" }
.after.gfx-home:after { content: "\E008" }
.after.gfx-lock:after { content: "\E009" }
.after.gfx-logo:after { content: "\E00A" }
.after.gfx-mail:after { content: "\E00B" }
.after.gfx-pin:after { content: "\E00C" }
.after.gfx-tiles:after { content: "\E00D" }
.after.gfx-triangle-thin-down:after { content: "\E00E" }
.after.gfx-triangle-thin-left:after { content: "\E00F" }
.after.gfx-triangle-thin-right:after { content: "\E010" }
.after.gfx-triangle-thin-up:after { content: "\E011" }
.after.gfx-twitter:after { content: "\E012" }
.after.gfx-user:after { content: "\E013" }
.after.gfx-search:after { content: "\E014" }
.after.gfx-triangle-right:after { content: "\E015" }
.after.gfx-triangle-up:after { content: "\E016" }
.after.gfx-triangle-left:after { content: "\E017" }
.after.gfx-triangle-down:after { content: "\E018" }
.after.gfx-hamburger:after { content: "\E019" }
.after.gfx-external:after { content: "\E01A" }
.after.gfx-download:after { content: "\E01B" }
.after.gfx-upload:after { content: "\E01C" }
.after.gfx-file:after { content: "\E01D" }
.after.gfx-check:after { content: "\E01E" }
.after.gfx-dot:after { content: "\E01F" }
.after.gfx-google-plus:after { content: "\E020" }
.after.gfx-linkedin:after { content: "\E021" }
