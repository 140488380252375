@import (reference) '../../core/colors';

.news-slider {
	position: relative;
	background: white;

	.tag {
		display: block;
		position: absolute;
		top: 0; left: 0;
		padding: 0.5em 1em;
		z-index: 1;
		.branded-background(true);
	}

	.slick-dots {
		position: absolute;
		right: 1em;
		bottom: 1em;
		width: auto;
		z-index: 2;

		transform:none;
		left: auto;
		

		.dot {
			background: rgba(0,0,0,0.15);
			.lt-ie9 & {
				background: @grey;
			}
		}
		.slick-active .dot {
			background: rgba(0,0,0,0.5);
			.lt-ie9 & {
				background: @grey-dark;
			}
		}
	}
}

.news-slider--slide {
	position: relative;
	.clearfix();

	.box--link {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		z-index: 1;
	}
}

.news-slider--img, .news-slider--text {
	width: 50%;

	@media @mini-mobile {
		width: 100%;
	}
}

.news-slider--img {
	position: absolute;
	height: 100%;
	background: white;

	@media @mini-mobile {
		position: relative;
		padding-top: 50%;
	}

	.background-size-cover {
		@media @min-mobile {
			right: 5px;
			left: 0;
			width: auto;
		}
	}
}

.news-slider--text {
	position: relative;
	margin-left: 50%;
	padding-bottom: 60px;
	min-height: inherit;

	@media @mini-mobile {
		margin-left: 0;
	}

	.link {
		position: absolute;
		bottom: 0.7em;
		z-index: 2;
	}
}