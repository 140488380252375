@import (reference) '../../core/vars';

.home {
	.row {
		margin-right: -5px;
		margin-left: -5px;

		> div {
			padding-right: 5px;
			padding-left: 5px;
		}

		&.inner > div {
			margin-top: 0;

			.lt-ie9 & {

				&[class*="col-sm"] {
					margin-top: 0;
				}

				&[class*="col-lg"], .col-md-12 {
					margin-top: 10px;

					&:first-child {
						margin-top: 0;
					}
				}
			}

			@media @max-tablet {
				&[class*="col-md"] {
					margin-top: 10px;

					&:first-child {
						margin-top: 0;
					}
				}
			}

			@media @min-tablet {
				&[class*="col-sm"] {
					margin-top: 0;
				}
			}
			// Fix for full width cols
			@media @min-laptop {
				&[class*="col-md-12"] {
					margin-top: 10px;

					&:first-child {
						margin-top: 0;
					}
				}
			}

			@media @mobile {
				[class*="col-sm"] {
					margin-top: 10px;

					&:first-child {
						margin-top: 0;
					}
				}
			}
		}
	}
}

.universitas {

	div.content {

		position: relative;
		//margin-top: 10px;

		span.tag {
			display: block;
			position: absolute;
			top: 0; left: 0;
			padding: 0.5em 1em;
			z-index: 1;
			.branded-background(true);
		}

		article.box{
			margin-top: 10px;
			margin-bottom:10px;
			span.tag {
				display: block;
				position: absolute;
				top: 0; right: 0; left:75%;
				padding: 0.5em 1em;
				z-index: 1;
				background-color:@grey-dark;
				//.branded-background(true);
			}
		}
	}
	.articles{
		@media @desktop {
			.col-md-6, .col-md-4 {
				margin-top: 0;
			}
		}
	}

}
