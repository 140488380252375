@import (reference) '../../core/vars';

.map {
	position: relative;
	padding-top: 44%;
	background: rgb(239, 236, 228);

	img {
		max-width: none;
	}
}

.map--container {
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
}