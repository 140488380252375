@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

.filter--title {

	@media @min-tablet and @max-tablet {
		&:after{
			color: @grey-dark;
			font-size: 0.8em;
		}
	}
	
	@media @max-tablet {
		display: block;

		.gfx(close, after);

		&:after {
			position: absolute;
			right: 1em;
			transition: transform 300ms;
			transform: rotate(45deg);
		}

		&[data-accordion-state^='open'],
		&[data-expander-state^='open'] {
			&:after {
				transform: rotate(180deg);
			}
		}
	}

	@media @mobile {
		padding: 1em;
	}
}