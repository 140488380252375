@import (reference) '../../core/colors';
@import (reference) '../../core/mixins';

.agenda--tags {
	margin-right: -3px;
	margin-bottom: -6px;
	margin-left: -3px;
	
	&:extend(.clearfix all);

	.agenda--tag {
		display: block;
		float: left;
		padding: 3px 0.5em;
		margin-right: 3px;
		margin-bottom: 6px;
		margin-left: 3px;
		background: @grey-light;
	}
}