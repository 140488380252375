@import (reference) '../../../core/vars';

// Import desktop and mobile styles
@media @desktop {
	@import 'fixed-header-desktop';
}
@media @mobile {
	@import 'fixed-header-mobile';
}

// Global styles
#wrapper .fixed-header {
	position: fixed;
	top: 0;
	width: 100%;
	display: block;
	z-index: 3;
}

#header {
	z-index: 2;
}

#main {
	position: relative;
	//z-index: 1;
}