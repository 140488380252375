.language-switch {
	position: relative;
	float: left;

	.toggle-language-switch {
		font-family: @bold;
		position: relative;
		border-right: 1px solid @grey;
		
		.gfx(triangle-down, after);
		
		&:after {
			font-size: 0.6em;
			margin-left: 5px;
			transition: transform 500ms;
		}

		&[data-accordion-state^='open'] {
			&:after {
				transform: scaleY(-1);
			}
		}
	}

	.dropdown {
		position: absolute;
		z-index: 2;
	}
	
	ul {
		list-style: none;
		padding-left: 0;
		margin-top: 0;
		margin-bottom: 0;
	}

}