@import (reference) '../../../core/vars';
@import (reference) '../../../core/mixins';

// Flexbox styling and fallback
@import 'breadcrumbs-legacy';
@import 'breadcrumbs-flexbox';

.breadcrumbs {
	float: right;
}

.breadcrumbs--content {
	position: absolute;
	left: 0;
	width: 100%;
	line-height: @line-height;
	background: rgba(255,255,255,0.9);

	ul {
		margin-top: 0;
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
		.clearfix();
	}

	li {
		&.gfx {
			&:before {
				padding-top: 15px;
				font-size: 0.8em;
			}
		}
	}

	a {
		display: block;
		padding: 14px 0;
	}

	// Scroll mode
	.breadcrumbs--mobile.expanded {
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;

		ul {
			&:extend(.clearfix all);
		}

		li {
			float: left;
			padding: 0 0.33em;

			&.expanded {
				.long { display: block; }
				.short { display: none; }
			}
		}
	}
}

.breadcrumbs--mobile {
			
	// Hide long links
	.long {
		display: none;
		white-space: nowrap;
	}
}

.breadcrumbs--slider {
	&:extend(.clearfix all);
}

.breadcrumbs--toggler {
	border-left: 1px solid @grey;

	&[data-accordion-state^='open'] {
		.gfx {
			.gfx(close);
		}
	}
}