@import (reference) '../../../core/vars';
@import (reference) '../../../core/header';
@import (reference) '../../../core/colors';

.search {
	float: left;
	font-size: 15px;
	border-right: 1px solid @grey;
}

.search--toggler {
	background: white;
	transition: background 300ms;

	&:hover {
		background: @grey-light;
	}

	&[data-accordion-state^='open'] {
		i {
			.gfx(close);
		}
	}
}

.search--input {
	position: absolute;
	top: 57px;
	bottom: 0;
	width: 100%;
	left: 0;
	background: white;
	z-index: 2;
	transition-duration: 500.0ms !important;

	> .container {
		position: relative;
		padding-top: 1em;
		padding-bottom: 1em;
		overflow: auto;
		height: ~"calc(100vh - 56px)";
		-webkit-overflow-scrolling: touch;
		.gfx(search);

		&:before {
			position: absolute;
			top: 15px;
			font-size: 24px;
			line-height: 1.4em;
			//left: 0;
		}
	}

	input[type="text"] {
		font-size: 24px;
		padding: 0 0 0.5em 1.5em;
		background: transparent;
		border: none;
		border-bottom: 1px solid @anthracite;
	}

	//input[name="searchdomain"]:checked + label{
	//	background-color:@marine;
	//	color: @white;
	//}
}