// Shared
@import 'shared/index';

// Navigation
@import 'modules/navigation/navigation';

// ScrollTop
@import 'modules/navigation/scrolltop';

// Scaffolding
@import 'modules/scaffolding/index';

// Controls
@import 'modules/controls/controls';

// Content
@import 'modules/content/index';

// Banner
@import 'modules/banner/index';

// Accordion
@import 'modules/accordion/accordion';

// Slider
@import 'modules/slider/slider';

// Home
@import 'modules/home/home';

// News
@import 'modules/news/news';

// Agenda
@import 'modules/agenda/agenda';

// Dropdown
@import 'modules/dropdown/index';

// Filter
@import 'modules/filter/index';

// FAQ
@import 'modules/faq/faq';

// Register
@import 'modules/register/index';

// Content teaser
@import 'modules/content-teaser/index';

// Map
@import 'modules/map/map';

// Video
@import 'modules/video/index';

@import 'modules/scaffolding/print';


// Hide stupid browsersync message
// TODO: remove this
#__bs_notify__ { display: none !important; }