
// If transitions are not supported, the menu will jump,
// there is no jquery animate fallback
.no-csstransforms3d {
	.pusher {
		position: relative;
		left: 0;
		transition: left 500ms;
	}
	
	body.off {
		.pusher {
			left: 100%;
		}
	}

	.offcanvas {
		transition: right 500ms;
		right: 100%;
	}
}