// Use before element on #main to overlay the content
// of the page. Problem: menu on desktop does not get
// overlaid.
// 
// TODO: Use transparent PNG as fallback for opacity
// Background on :before does not work in IE8
#main {
	.lt-ie9 & {
		.fixed-page-overlay {
			display: none;
			background-color: none;
			background-image: url(~'@{assets}/gfx/transparent-black.png');
			background-repeat: repeat;
			position: fixed;
			z-index: 100;
			height: 100%;
			width: 100%;
			top: 0; right: 0; bottom: 0; left: 0;

		}
	}
	.lt-ie9 .fixed-page-mode & .fixed-page-overlay {
		display: block;
	}
	&:before {
		content: '';
		position: fixed;
		top: 0; right: 0; bottom: 0; left: 0;
		z-index: 1;
		background: transparent;
		transition: background 300ms;
		visibility: hidden;

		.fixed-page-mode & {
			background: rgba(0,0,0,0.7);
			
			.no-rgba & {
				zoom: 1;
				content: ' ';
				z-index: 200;
			}
		}

		.overlay-visible & {
			visibility: visible;
		}
	}

}