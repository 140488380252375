@import (reference) '../../../core/colors';

// Result styling
.search--results {
	padding: 2em 0 1em;
	list-style: none;
	padding-left: 0;

	li {
		position: relative;

		&:before {
			content: '';
			display: block;
			height: 1px;
			width: 20%;
			margin: 1em 0;
			background: @anthracite;
		}

		&:first-child {
			&:before {
				content: none;
			}
		}
	}

	.blank-slate {
		padding: 30px 0;
		color: @grey-dark;
		font-size: 22px;
		text-align: center;
	}

	// Hide blank slate if there are
	// results
	li + .blank-slate {
		display: none;
	}
	
	// Matches
	b {
		color: @sky;
	}

	br {
		//display: none;
	}
}

.search--result-link {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
}

.search--more-results {
	color: @anthracite-light;
	transition: color 250ms;

	&:hover {
		color: @sky;

		i.gfx {
			.bounce-down();
		}
	}
}