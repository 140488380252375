//@import (inline) '../../../bower_components/chosen/public/chosen.min.css';
@import (reference) '../../core/colors';
@import (reference) '../../core/vars';
@import (reference) '../../core/mixins';

select {
	width: 100%;
}

.chosen-container {
	position: relative;
}

.chosen-single {
	display: block;
	padding: 0.5em;
	border: 1px solid @grey-dark;
	cursor: default;
	.gfx(triangle-down, after);

	&:after {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0.5em;
		transition: transform 300ms;
	}

	.chosen-with-drop & {
		&:after {
			transform: scaleY(-1);
		}
	}

	.chosen-container-active &{
		border-color: @marine;
	}
}

.chosen-drop {
	display: none;
	position: absolute;
	width: 100%;
	background: white;
	border: 1px solid @grey-dark;
	border-top-width: 0;
	z-index: 1;

	.chosen-with-drop & {
		display: block;
	}
}

.chosen-container-single-nosearch {
	.chosen-search {
		display: none;
	}
}

.chosen-results {
	list-style: none;
	padding-left: 0;

	li {
		padding: 0.5em;
		background: white;
		border-top: 1px solid @grey-dark;
		transition: background 300ms;
		
		&:hover {
			background: @grey-light;
		}

		&:before {
			content: none;
		}

		&:first-child {
			border-top: none;
		}
	}
}

/////////////// DEAD

/* @group Multi Chosen */
.chosen-container-multi{
	.chosen-choices {
		position: relative;
		overflow: hidden;
		margin: 0;
		padding: 0 5px;
		width: 100%;
		min-height: 35px;
		border: 1px solid @grey-dark;
		background-color: @white;
		//background-image: linear-gradient(#eee 1%, #fff 15%);
		cursor: text;
	}
	.chosen-choices li {
		float: left;
		list-style: none;
		&.search-field {
			margin: 0;
			padding: 0;
			white-space: nowrap;
			input[type="text"] {
				margin: 1px 0;
				padding: 0;
				height: 38px;
				outline: 0;
				border: 0 !important;
				background: transparent !important;
				box-shadow: none;
				color: #999;
				//font-size: 100%;
				font-family: sans-serif;
				line-height: normal;
				border-radius: 0;
				width: 38px;
			}
		}
		&.search-choice {
			position: relative;
			margin: 3px 5px 3px 0;
			padding: 3px 20px 3px 5px;
			border: 1px solid @grey-dark;
			max-width: 100%;
			border-radius: 0px;
			background-color: @grey-light;
//			background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
			background-size: 100% 19px;
			background-repeat: repeat-x;
			background-clip: padding-box;
//			box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(#000,.05);
			color: #333;
			//line-height: 13px;
			cursor: default;
			span {
				word-wrap: break-word;
			}
			.search-choice-close {
				position: absolute;
				top: 8px;
				right: 3px;
				display: block;
				width: 12px;
				height: 12px;
				background: url("@{assets}/img/close.png") 50% 50% no-repeat;
				background-size:cover;
				filter: invert(100%);
				-webkit-filter: invert(100%);
				//background-color:red;
				font-size: 1px;
			}
		}
		&.search-choice-disabled {
			padding-right: 5px;
			border: 1px solid #ccc;
			background-color: #e4e4e4;
			//background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
			color: #666;
		}
		&.search-choice-focus {
			background: #d4d4d4;
			.search-choice-close {
				background-position: -42px -10px;
			}
		}
	}
	.chosen-results {
		margin: 0;
		padding: 0;
		z-index: 1;
	}
	.chosen-drop .result-selected {
		display: list-item;
		color: #ccc;
		cursor: default;
	}
	.chosen-drop, .chosen-results {
		overflow:visible;
		overflow-y: visible;
	}
}
