@import (reference) '../../../core/colors';
@import (reference) '../../../core/header';
@import (reference) '../../../core/vars';

// For every decent browser
#wrapper .fixed-header {

	font-size: 13px;
	
	// Desktop grey background
	.fixed-header--bg {
		background: @grey-light;
	}
	
	// IE8 grey background
	/* .lt-ie9 & {
		background: @grey-light;
	} */

	> .container {
		&:extend(.clearfix all);
	}
}