@import (reference) '../../core/vars';

.link-list {
	list-style: none;
	padding-left: 0;

	li {
		border-top: 1px solid white;
		&:before { content: none; }
		&:first-child {
			border-top: none;
		}

		.bg-white &,
		.bg-orange & { border-color: @anthracite; }

		.bg-branded & {
			border-color: white;
			.anthracite & { border-color: white; }
			.marine & { border-color: white; }
			.orange & { border-color: @anthracite; }
			.green & { border-color: white; }
			.red & { border-color: white; }
			.purple & { border-color: white; }
			.sky & { border-color: white; }
		}
	}

	.link {
		padding: 0.75em 0;
		font-family: @regular;
	}
}