@import (reference) '../../core/vars';

@space-between: 30px;
@space-between-mobile: @space-between/2;
@gutter: @space-between/2;
@gutter-mobile: @space-between-mobile/2;

.content-teaser {
	// here to maintain inner margin  of
	// module elements.
}

.content-teaser--outer {
	margin: @gutter * -1;

	@media @mobile {
		margin: @gutter-mobile	* -1;
	}

	.flexbox &, .flexboxlegacy & {
		display: flex;
		flex-wrap: wrap;
	}

	.no-flexbox.no-flexboxlegacy & {
		&:extend(.clearfix all);
	}
}

.content-teaser--inner {
	position: relative;
	padding: @gutter;
	width: 100%/3;
	transition: opacity 300ms;

	&.faded {
		opacity: 0.5;
	}

	@media @max-laptop {
		width: 100%/2;

		.full-width & {
			width: 100%/3;
		}
	}

	@media @max-tablet {
		.full-width & {
			width: 100%/2;
		}
	}

	@media @mobile {
		padding: @gutter-mobile;
	}

	@media @max-mini-mobile {
		width: 100%;

		.full-width & {
			width: 100%;
		}
	}

	.no-flexbox.no-flexboxlegacy & {
		float: left;
	}
}

.content-teaser--title {
	width: 100%;
	padding-right: @gutter;
	padding-left: @gutter;
	margin-bottom: @gutter * 2;
	margin-top: @gutter * 2;

	&:first-child {
		margin-top: @gutter;
	}

	/* @media @mobile {
		padding-right: @gutter-mobile;
		padding-left: @gutter-mobile;
		margin-bottom: @gutter-mobile;
		margin-top: @gutter-mobile * 2;
	
		&:first-child {
			margin-top: @gutter-mobile;
		}
	} */

	.no-flexbox.no-flexboxlegacy & {
		float: left;
	}
}