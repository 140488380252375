@import (reference) '../../core/mixins';
@import (reference) '../../core/vars';

.floating-text {
	margin-right: -15px;
	margin-left: -15px;

	&:extend(.clearfix all);

	.inner {
		overflow: hidden;
	}

	.inner, figure {
		padding: 0 15px;
	}

	figure {
		max-width: 50%;
	}

	&.image-right {
		figure { float: right; }
	}

	&.image-left {
		figure { float: left; }
	}

	@media @max-mini-mobile {
		&.image-right, &.image-left {
			
			.inner {
				margin-top: 1em;
			}

			figure {
				float: none;
				max-width: none;
			}
		}
	}
}