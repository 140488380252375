@import (reference) '../../core/vars';
@import (reference) '../../core/mixins';
@import (reference) '../../core/colors';

.filter--alphabet {
	padding-left: 0;
	&:extend(.clearfix all);

	> label {
		float: left;
		border: none;

		&:first-child {
			border-top: none;
		}

		> input[type='checkbox'],
		> input[type='radio'] {
			position: absolute;
			visibility: hidden;
			opacity: 0;
		}

		> input:checked + span {
			background: @grey;
		}
	}
}

.filter--square {
	@ratio: 100%/6;
	position: relative;
	display: block;
	width: @ratio;
	padding-top: @ratio;

	@media @max-tablet {
		width: 100%/26;
		padding-top: 100%/26;
	}

	@media @max-mobile {
		width: 100%/13;
		padding-top: 100%/13;
		color: @anthracite;
	}

	@media @max-mini-mobile {
		width: 100%/7;
		padding-top: 100%/7;
	}

	> span {
		.csstransforms & {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			//top: 50%; left: 50%;
			//transform: translate(-50%, -50%);
		}

		> span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}