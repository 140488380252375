@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

@space-between: 30px;
@space-between-mobile: @space-between/2;
@gutter: @space-between/2;
@gutter-mobile: @space-between-mobile/2;

.news--teaser {
	padding-bottom: @space-between*2 + 21px;
	cursor:pointer;

	@media @mobile {
		padding-bottom: @space-between-mobile*2 + 21px;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: @gutter;
		left: @gutter;
		right: @gutter;
		height: 1px;
		background: @grey-dark;

		@media @mobile {
			right: @gutter-mobile;
			bottom: @gutter-mobile;
			left: @gutter-mobile;
		}
	}

	.banner--background-image {
		padding-top: 50%;
	}

	.link {
		position: absolute;
		bottom: @gutter*3;

		@media @mobile {
			bottom: @gutter-mobile*3;
		}
	}
	>p:first-of-type{
		color:@anthracite-light;
	}
	>p:nth-of-type(2){
		margin-top:0;
	}
}

// Overwrite content-teaser styles
&.news--top-story {
	width: 100% !important;
}