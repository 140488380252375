@import (reference) '../../core/colors';
@import (reference) '../../core/mixins';

.input.file {
	position: relative;

	input {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		// Hide the upload field
		opacity: 0.0000001;
		width: 100%;
		cursor: pointer;
		// Hide the upload button
		text-indent: -1000px;
		color: @grey;

		.lt-ie9 & {
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
		}
		
		&:hover {
			& + label {
				&:before {
					.bounce-up();
				}
			}
		}

		&:focus {
			& + label {
				border-color: @marine;
			}
		}

		&[disabled] {
			cursor: default;

			& + label {
				cursor: default;
				color: @grey-dark;
				&:before {
					animation: none;
				}
			}
		}
	}

	label {
		position: relative;
		cursor: pointer;
		display: block;
		padding: 0.5em 0.5em 0.5em 2.1em;
		border: 1px solid @grey-dark;
		text-overflow: ellipsis;
		overflow: hidden;
		transition: border 300ms;

		&.placeholder {
			color: @anthracite-light;
		}

		.gfx(arrow-up);
		&:before {
			position: absolute;
			top: 1.5em;
			left: 12.5px;
			font-size: 0.5em;
		}
		&:after {
			position: absolute;
			left: 5px;
			content: '\E01D';
			font-family: 'fonticons';
			font-size: 1.5em;
		}
	}

	&.error {
		label {
			border-color: @red;
		}
	}
}