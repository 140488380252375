// Hidden elements
@media print {

	// Navigation
	.push-menu
	, .meta-menu
	, .search--toggler
	, .search--input
	, .breadcrumbs--toggler
	, .language-switch
	, .service-menu nav
	, #footer
	, .square.logo
	, .open-offcanvas
	, .close-offcanvas

	// Body
	, .banner
	, .sub-menu--spacer
	, .filter--search
	, .sub-menu
	, .news--controls
	, .social-links
	{
		display: none !important;
	}
	
	// Layout fixes
	
	#header, .fixed-header {
		height: auto !important;
		position: relative !important;
		transform: translate3d(0,0,0);
		top: 0 !important;
		&:after {
			background: none !important;
		}
	}

	.sub-menu {
		position: relative !important;
		top: 0;
	}

	.service-menu {
		height: auto !important;
		margin: 2em 0;

		> .hidden-mobile {
			display: block !important;
		}
	}

	.box {
		page-break-inside: avoid;
	}

	.breadcrumbs {
		width: 100% !important;
		float: none !important;
		font-size: 12px !important;

		a, li:before {
			padding: 3px 0 !important;
		}

		.breadcrumbs--slider {
			display: block !important;
			.clearfix();

			> li {
				margin: 0 0.5em;
				float: left;
			}
		}

		.short {
			display: none !important;
		}

		.long {
			display: block !important;
		}
	}

	.deepcrumbs {
		padding: 0 !important;

		a:after {
			content: none !important;
		}
	}

	#main {
		padding-top: 0 !important;
		background-color: transparent;
	}

	.overlap {
		margin-top: 0 !important;
	}

	[data-accordion-content] {
		height: auto !important;
	}

	.breadcrumbs--content {
		position: relative;
	}

	[data-lightbox]:after {
		content: none !important;
	}

	// Reverse mobile table styles
}

// Generic styles
@media print {
    *,
    *:before,
    *:after {
        //background-color: transparent !important;
        //color: #000 !important; // Black prints faster: h5bp.com/s
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a[href]:after {
        content: " (" attr(href) ")";

    }
    .link:after {
    	content: " (" attr(href) ")" !important;
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; // h5bp.com/t
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}