@import (reference) '../../../core/vars';

@media @desktop {
	@import 'footer-desktop';
}

@media @mobile {
	@import 'footer-mobile';
}

// General styles
#footer {
	color: white;
	background: @marine;
	font-size: 13px;

	ul {
		list-style: none;
		padding-left: 0;
		font-family: @light;
		margin: 0;
	}

	p {
		margin: 0;
	}

	h6 {
		font-size: inherit;
		font-family: @bold;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 0;
	}

	.footer-menu--contact {
		ul {

			li:first-child { margin-top: 0; }

			a {
				position: relative;
				display: block;
				padding-left: 1.5em;
				font-family: @bold;

				.gfx(arrow-right);

				&:before {
					position: absolute;
					left: 0;
					font-size: 0.8em;
				}
			}
		}
	}

	.footer-menu--meta {
		padding: 0.5em 0;
		background: @marine-light;
		
		> .container{
			&:extend(.clearfix all);
		}

		.footer--copyright {
			font-size: 10px;
		}

	}
}

.footer--social-links {

	a {
		margin-right: 1em;
		color: white;
		text-decoration: none !important;
		&:hover {
			color: white;
		}
	}
}