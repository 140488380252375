@import (reference) '../../../core/vars';
@import (reference) '../../../core/colors';

#header {
	position: fixed;
	width: 100%;
	top: 32px;

	.branded-line();
}

.push-menu {
	z-index: 1;
	position: relative;

	li {
		position: relative;
	}

	// All levels but .default (the first)
	.level:not(.default) {
		position: absolute;
		z-index: 2;
		visibility: hidden;
		opacity: 0;

		// No legacy styles needed, on IE < 10 the
		// .levels just snap in and pop out without
		// transition
		transform: scale(0.95) translate(0, 10%);
		transition: transform 300ms, opacity 300ms;
	}

	.lt-ie9 & {
		.level {
			position: absolute;
			visibility: hidden;
			opacity: 0;
			
			&.default {
				position: relative;
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.hover {
		> .level {			
			opacity: 1;
			transform: scale(1) translate(0, 0);
		}
	}

	.visible {
		> .level { visibility: visible; }
	}

	& > .level {
	
		// Second level
		> ul > li > .level {
			top: 100%;
			
			// Third level
			> ul > li > .level {
				top: 0;
				left: 100%;
				border-left: 1px solid transparent;
				border-right: 1px solid transparent;
			}
		}
	}
}