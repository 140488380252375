@import (reference) '../../core/vars';

.key-value{

	tr {
		border: none;

		@media @mobile {
			&:before {
				padding-bottom: 0;
			}
		}
	}

	th, [data-title]:before{
		text-align: left;
		vertical-align: top;
	}

	@media @mobile{
		th{ display: none; }
	}
}