@import (reference) '../../../core/vars';
@import (reference) '../../../core/mixins';
@import (reference) '../../../core/colors';

.deepcrumbs {
	padding: 20px 0;
	color: @ochre;
	font-size: 13px;

	ul {
		.nav-ul(true);
	}

	li {
		padding-left: 0.5em;

		&:before {
			content: '·';
			padding-right: 0.5em;
		}

		&:first-child {
			padding-left: 0;

			&:before {
				content: none;
			}
		}

		&:last-child {
			font-family: @bold;
		}
	}

	a {
		display: inline-block;
		transition: color 300ms;
		&:hover {
			color: @anthracite;
		}
		&.bold {
			margin-top: 1px; margin-bottom: -1px;
		}
	}

	@media @mobile {
		display: none;
	}
}
