@import (reference) '../../../core/vars';
@import (reference) '../../../core/colors';
@import (reference) '../../../core/mixins';

@bar-width : 3px;
@bar-height : 100%;


.sub-menu {

	/*> nav {
		.inner(0.5em);
		margin-left:-30px;
		> h4{
			padding-left: 30px;
		}


		//not happy with this structure. Would like to revisit
		& > div > ul > li {
			& > a{
				margin-left: 30px;				
			}

			& > div > ul > li{
				& > a {
					margin-left: 30px;						
					padding-left: 30px;
				}

				& > div > ul > li{
					margin-left: 0px;						
					padding-left: 30px;
					
					& a{
						padding-left: 60px;
						
					}
					&.active{
						& a{
							padding-left: 57px;
						}
					
					}
				}
			}

			&.active{
				& > a{
					margin-left: 27px;
				}				
			}
			
		}
		& li.active{
			border-left: 3px solid @cobalt-blue;			
		}
		
		
	}*/


	> nav{
		> h4{
			padding-left: 30px;
		}

		& .reset-container{
			margin-left: 30px;
		}


		& > div{
			& [aria-expanded='true']{
				//background-color: red;	
				&:before{
					width: @bar-width;
					height: @bar-height;
					background-color: white;				
					content: " ";
					display:inline-block;
					position: absolute;
					//top: 0;
				}
			}
			
		}

		margin-left: -30px;
		position: relative;
		& li{
			position: relative;

			&:before{
				width: @bar-width;
				height: @bar-height;
				background-color: transparent;
				content: " ";
				display:inline-block;
				position: absolute;
			}
			&.active:before{
				background-color: @cobalt-blue;
			}
			&>a{
				margin-left: 30px;
			}

			/* Try to identify Windows Edge*/
			@supports (-ms-ime-align:auto) {
				/* EXPERIMENTAL : apply a correction to the height by cutting the margin */
				&>a{
					margin-top:-21px;
				}
			}
			/* Try to identify IE 10+
			input https://stackoverflow.com/questions/18907131/detecting-ie11-using-css-capability-feature-detection
			*/
			@media screen and (-ms-high-contrast:active),
			(-ms-high-contrast:none) {
				/* EXPERIMENTAL : apply a correction to the height by cutting the margin */
				&>a{
					margin-top:-21px;
				}

			}


			& li{
				&:before{
					width: @bar-width;
					height: @bar-height;
					background-color: transparent;					
					content: " ";
					display:inline-block;
					position: absolute;	
				}
				&.active:before{
					background-color: @cobalt-blue;		
				}

				&>a{
					padding-left: 15px;
				}

				/* Try to identify Windows Edge*/
				@supports (-ms-ime-align:auto) {
					/* EXPERIMENTAL : apply a correction to the height setting margin to 0 */
					&>a{
						margin-top:0;
					}
				}
				/* Try to identify IE 10+
				input https://stackoverflow.com/questions/18907131/detecting-ie11-using-css-capability-feature-detection
				*/
				@media screen and (-ms-high-contrast:active),
				(-ms-high-contrast:none) {
					/* EXPERIMENTAL : apply a correction to the height setting margin to 0 */
					&>a{
						margin-top:0;
					}
				}



				& li{
					&:before{
						width: @bar-width;
						height: @bar-height;
						background-color: transparent;						
						content: " ";
						display:inline-block;
						position: absolute;	
					}	
					&.active:before{
						background-color: @cobalt-blue;		
					}
					&>a{
						padding-left: 30px;
					}						
				}						
			}
		}
	}

	
	.sub-menu--content {
	
		@media @min-laptop {
			// Overwrite mobile accordion styles
			height: auto !important;
		}

		// Overwrite mobile accordion styles
		max-height: none !important;
		visibility: visible !important;

		@media @min-tablet and @max-tablet {
			padding-bottom: 1px;
		}

		> ul {
			padding-left: 0;

			> li {
				// Fourth level
				//border-bottom: 1px solid @grey-dark;

				&:first-child {
					//border-top: 1px solid @grey-dark;
				}
			}
		}
	}
	
	.level {
		> ul {
			list-style: none;
			padding-left: 0;
			
			> li {
				// Fifth level
				//padding-left: 1.5em;
				//border-top: 1px solid @grey-dark;
			}
		}
	}


	li {
		
		&:before {
			content: none;
		}

		a {
			display: block;
			padding: 0.5em 1.8em 0.5em 0;
			border-top: 1px solid @grey-dark;

		}
		&.active{			
			
		}
	}

	.deeper {
		position: relative;

		i {
			position: absolute;
			right: 0;
			top: 0;
			padding: 0.5em;
			line-height: 21px;

			&:before {
				font-size: 0.8em;
				transition: transform 300ms;
			}
			
			&[data-expander-state^='open'] {
				&:before {
					transform: scaleY(-1);
				}
			}
		}
	}
}
