@import (reference) '../../core/colors';

a.toplink,a.toplink:hover{
  background-color:red;
  display: block;
  position: fixed;
  right: 16px;
  bottom: 22px;
  padding: 8px 10px;
  background: @anthracite;
  color: @white;
  text-decoration: none;
  z-index: 1000;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  opacity: 0;
  cursor: pointer;
}