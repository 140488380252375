@import (reference) '../../core/vars';
@import (reference) '../../core/header';

.square {
	display: block;
	padding: 5px 10px;

	@media @mobile {
		text-align: center;
		padding: 0;
		line-height: @mobile-header-height;
		width: @mobile-header-height;
	}
}