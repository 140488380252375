@import (reference) '../../core/colors';
@import (reference) '../../core/vars';

.tabcordion {

	// Desktop nav
	nav {
		&:extend(.clearfix all);

		@media @mobile {
			display: none;
		}

		a {
			display: block;
			float: left;
			padding: 0.5em 2em;
			margin-left: 3px;
			background: @grey;
			color: @marine;
			transition: background 300ms;

			&:first-child {
				margin-left: 0;
			}

			&:hover {
				background: darken(@grey, 5%);
			}

			&[data-tabcordion-state='open'] {
				background: @marine;
				color: white;
			}
		}
	}

	.accordion {

		@media @desktop {
			border: 1px solid @grey-dark;
		}

		.lt-ie9 & {
			border: 1px solid @grey-dark;
		}

		> li {

			&:before {
				content: none;
			}

			// Mobile tabs
			> a {
				display: block;

				@media @desktop {
					display: none;
				}

				.lt-ie9 & {
					display: none;
				}
			}
		}

		// Tab meccano
		@media @desktop {
			[data-tabcordion-state='open'] {
				height: auto !important;
			}
			[data-tabcordion-state='closed'] {
				height: 0;
			}
		}

		.lt-ie9 & {
			[data-tabcordion-state='open'] {
				height: auto !important;
			}
			[data-tabcordion-state='closed'] {
				height: 0;
			}
		}
	}
}