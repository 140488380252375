@import (reference) '../../core/colors';
@import (reference) '../../core/vars';
@import (reference) '../../core/mixins';

.input {
	.inner(0.5em);

	&.error {
		&:after {
			display: block;
			content: attr(data-error-msg);
			color: @red;
			padding: 0 0.5em;
		}
	}
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea,
textarea.form-control,
select,
select.form-control {
	outline: none;
	box-shadow: none;
	font-family: 'openSans-Regular-webfont', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
	width: 100%;
	padding: 0.5em;
	border: 1px solid @grey-dark;
	color: inherit;
	transition: border 300ms;
	border-radius: 0;

	&:focus {
		border-color: @marine;
		box-shadow: none;
	}
	
	&[disabled] {
		border-color: @grey !important;
		cursor: default;
	}

	.error & {
		border-color: @red;
	}
}

// Hide arrows on number input fields
input[type="number"] {
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}