@import (reference) '../../../core/vars';

// General rules
#wrapper, .pass-height {
	height: 100%;
}

// translate3d offcanvas for capable browsers
@import 'offcanvas-3d.less';

// Legacy fallback
@import 'offcanvas-legacy';

.offcanvas {

	// Prevent offcanvas from taking space in
	// page flow and set width according to
	// viewport coverage
	position: absolute;
	top: 0 !important;
	width: 100%;
}

// Same effect as show-fake-scrollbar, but
// without the class, so the resizing problem
// is no longer caused.
body.off {
	@media @mobile {
		overflow-y: scroll;

		#wrapper {
			overflow: hidden;
			height: 100%;
		} 
	}
}