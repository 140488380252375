@import (reference) '../../../core/vars';
@import (reference) '../../../core/mixins';
@import (reference) '../../../core/colors';

.service-menu {
	position: relative;
	margin-top: 0px;
	background: white;

	> .hidden-mobile {
		padding: 1em 0;
	}
	
	ul {
		float: right;

		.nav-ul(true);
	}

	li {
		border-left: 1px solid @anthracite;
		&:first-child { border-left: none; }
		&:last-child a { padding-right: 0; }
	}

	a {
		//display: block;
		padding: 0 0.5em;
		text-transform: uppercase;
		font-size: 13px;

	}

	.logo {
		margin: 20px 0;

		@media @min-laptop{
			margin-bottom: 1px;	
		}
		
		.clearfix();

		a {
			display: block;
			padding: 0;
			float: left;
		}

		img {
			width: 240px;
		}
	}

	.service-menu--relation {
		position: relative;
	}

	.service-menu--department {
		position: absolute;
		right: 1em;
		bottom: -0.25em;
		text-align: right;
		margin-top: 20px;
		width: 70%;

		a {
			color: @anthracite;
			text-transform: none;
			font-size: 15px;
		}

	}
}