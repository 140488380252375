@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

.filter--emphasized {

	li {
		border: none;
		&:first-child {
			border-top: none;
		}

		label {
			span {
				border: 1px solid @marine;
				transition-duration: 0;

				&:after {
					color: white;
				}
			}
			input:checked + span {
				background: @marine;
				color: white;
			}
		}
	}
}