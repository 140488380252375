@import (reference) '../core/mixins';
@import (reference) '../core/vars';

.float-right { float: right; }
.float-left { float: left; }

.text-align-right { text-align: right; }
.text-align-left { text-align: left; }
.text-align-center { text-align: center; }

.clearfix { .clearfix(); }
.inner { .inner(); }
.inner-30 { .inner(30px); @media @mobile { .inner(); } }
.inner-05 { 
	.inner(0.5em); 
	& > h6{
		margin-left: 30px;
	}
}
.inner-10 { .inner(10px); }
.sr-only { .sr-only(); }

.bold { font-family: @bold; }
.lead { font-family: @semibold; margin-bottom: 0; }
p.leadmxm { font-family: @semibold; margin-bottom: 0; font-size: ( @font-size + 2); }

.line { height: 2px; }

.overflow-hidden { overflow: hidden; }
.hidden, .visible-lt-ie9 { display: none; }

#main.universitas-article{
	@media @desktop {
		.no-pad-right { padding-right:0;}
		.no-pad-left { padding-left:0;}
	}
	@media @min-tablet{
		.no-pad-right { padding-right:15px;}
		.no-pad-left { padding-left:15px;}
	}
}


/* Quick fix for Box 6 (add margin bottom to first element only on small devices) */
@media @mobile {
	.xs-mb:first-child { 
		margin-bottom: 1em;
	}
}