.flexbox, .flexboxlegacy {
	.breadcrumbs--content {

		.breadcrumbs--mobile {

			// Short mode
			&.shortened {
				ul {
					display: flex;
					justify-content: space-around;
				}
			}
		}
	}
}