@import (reference) '../../core/colors';

.accordion {
	margin-bottom: 0;
	padding-left: 0;

	> li {
		&:before { content: none; }

		&:first-child {
			& > a {
				border-top: 1px solid @grey-dark;
			}
		}
	}

	[data-accordion-toggler] {
		display: block;
		position: relative;
		padding: 0.5em 1em;
		background: @grey;
		color: @marine;
		font-family: @semibold;
		border-bottom: 1px solid @grey-dark;
		transition: background 300ms;

		.gfx(triangle-down, after);

		&:after {
			position: absolute;
			right: 1em;
			transition: transform 300ms;
		}

		&:hover {
			background: darken(@grey, 5%);
		}

		&[data-accordion-state^='open'] {
			background: @marine;
			color: white;

			&:after {
				transform: scaleY(-1);
			}
		}
	}
}

.light {
	border-bottom: 1px solid @grey-dark;
	[data-accordion-toggler] {
		background: white;
		color: @marine;

		&[data-accordion-state^='open'] {
			background: @grey;
			color: @marine;
		}
	}
}
