@import (reference) '../../../core/colors';
@import (reference) '../../../core/header';

// For every decent browser
#wrapper .fixed-header {
	background: white;

	.branded-line();
	
	.fixed-header--bg {
		background: white;

		> .container {
			padding: 0;
		}
	}

	.open-offcanvas {
		float: left;

		.gfx(hamburger);

		&:before {
			font-size: 1.2em;
		}
	}

	.logo {
		float: left;
		font-size: 35px;

		.gfx(logo);
	}

	// test logo for others
	.voidlogo {
		float: left;
		font-size: 35px;

		.gfx(voidlogo);
	}
}