@import (reference) '../../core/mixins';

.slick-dots {
	width: 100%;

	
	@media @min-tablet{	
		transform: translateX(-50%);	
		left: 50%;		
	    width: 750px;
	}

	@media @min-laptop{	
	    width: 970px;	
	}
	@media @min-desktop{	
	    width: 1170px;
	
	}




	


	text-align: center;

	.nav-ul();

	@media @desktop {
		position: absolute;
		bottom: 1em;
	}

	.lt-ie9 & {
		position: absolute;
		bottom: 1em;
	}

	@media @mobile {
		margin-top: 0.5em;
	}

	.no-dots & {
		// Overpower inline style from slick slider
		display: none !important;
	}

	// Move dots to the right if the slider is in a banner
	.banner & {
		@media @desktop {
			text-align: right;
			padding-right: 3em;
			padding-left: 3em;
		}

		@media @max-tablet {
			text-align: center;
		}

		@media @mobile {
			padding-bottom: 0;
		}

		.content &{			
			width:auto;
			left:auto;
			right: 0;
			padding-left: 0;
			padding-right: 0;			
		}
	}

	li {
		display: inline-block;
		margin-left: 5px;
		&:first-child { margin-left: 0; }
		&:before { content: none; }

		&.slick-active .dot {
			@media @desktop {
				background: rgba(255,255,255,0.9);
			}
			.lt-ie9 & {
				background: @grey-dark;
			}
			@media @mobile {
				background: rgba(0,0,0,0.5);
			}
		}
	}

	.dot {
		border: none;
		outline: none;
		box-shadow: none;
		padding: 0;
		margin: 0;

		height: 10px;
		width: 10px;
		
		@media @desktop {
			background: rgba(255,255,255,0.6);
		}
		.lt-ie9 & {
			background: @grey;
		}
		@media @mobile {
			background: rgba(0,0,0,0.2);
		}
	}
}