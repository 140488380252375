@import (reference) '../../core/colors';
@import (reference) '../../core/vars';

.dropdown--wrapper {
	position: relative;
	width: 100%;

	// Exclude closed accordion contents from
	// page flow
	&[data-accordion-state='closed'] {
		position: absolute;
		left: 0; // THX, IE
	}

	.gfx-close {
		position: absolute;
		top: 0; right: 1em;

		@media @mobile {
			height: 48px;
			width: 48px;
			line-height: 48px;
			text-align: center;
			background: @marine;
			color: white;
		}
	}
}

.dropdown--content {
	border-bottom: 1px solid @grey-dark;
}