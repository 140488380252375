@import (reference) '../../core/colors';

.page-type {
	padding: 1em;
	text-align: center;
	border: 3px solid black;
	line-height: 3em;

	.branded-background(true);

	.banner & {
		position: absolute;
		height: 100%;
		top: 0;
		width: 100%;
	}
}

.color-tile {
	padding: 1em;
	text-align: center;
	border: 3px solid black;
	line-height: 3em;
}