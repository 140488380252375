.big-menu {
		
	// General links
	a {
		display: block;
	}
	
	// First level
	.default {
		> ul {
			.nav-ul(true);

			& > li {

				// First level links
				& > a {
					font-size: 17px;
					padding: 0.5em 1em;
				}

				&:first-child > a {
					padding-left: 0;
				}
				
				// Second level explicitly
				> .level {
					padding: 1em;
					box-shadow: 0 5px 10px rgba(0,0,0,0.4);
					.branded-background(true, false);
				}
			}
		}
	}

	// Second level
	.level2 {
		> ul {
			.nav-ul(false);
			.inner();

			> li {

				// Second level links
				> a {
					font-size: 17px;
					font-family: @semibold;
				}
			}
		}
	}
	
	// Third level
	.level3 {
		> ul {
			.nav-ul(false);

			> li {
				margin-top: 5px;
			}
		}
	}
}

.big-menu > .level {
	margin-left: -15px;

	a {
		text-decoration: none;
	}

	& > ul {
		.nav-ul(true);

		& > li {
			& > a {
				display: block;
				font-size: 17px;
				padding: 0.5em 1em;

				// Border bottom for active first level links
				position: relative;

				&:before, &:after {
					content: '';
					position: absolute;
					bottom: 0;
					top: auto;
					width: 0;
					height: 2px;
					transition: width 300ms;

					.branded-background();
				}
				&:before { left: 50%; }
				&:after { right: 50%; }

				&:hover:before, &:hover:after {
					width: 50%;
				}
			}

			// Activate border bottom
			&.hover > a,
			&.active > a {
				&:before, &:after {
					width: 50%;
				}
			}

			&:first-child{
				padding-left: 15px;

				& > a {
					padding-left: 0;
				}
			}
		}
		
	}
}