@import (reference) '../../core/vars';

.inline-images{
	margin-left: -0.5em;
	margin-right: -0.5em;

	&:extend(.inner all);
	&:extend(.clearfix all);

	> img, > a{
		display: inline-block;
		margin: 1em 0 0 0;
		float: left;
		padding: 0 0.5em;
		width: 50%;

		&:nth-child(-n+2){
			margin-top: 0;
		}
	}

	> a > img {
		display: inline-block;
	}

	.lt-ie9 & {
		a:first-child,
		img:first-child,
		a:first-child + a,
		img:first-child + img {			
			margin-top: 0;
		}
	}

	// > 480
	@media @min-mobile{
		
		> img, > a{
			width: 100%/3;

			&:nth-child(-n+3){
				margin-top: 0;
			}
		}
	}

	// > 1200
	@media @min-desktop{
		> img, > a{
			margin: 1em 0 0 0;
			width: 25%;
			float: left;

			&:nth-child(-n+4){
				margin-top: 0;
			}
		}
	}
}