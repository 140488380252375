@import (reference) '../../core/mixins';

.universitas .content .banner{
	margin-top: -30px;
	margin-left: -30px;
	margin-right: -30px;

	@media @mobile { margin:0; }
}

.universitas .box header span.tag {
	color: @anthracite-light;
	font-size: 80%;
}