@import (reference) '../../core/vars';

.background-size-cover {
	position: absolute;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-ms-behavior: url(~'@{assets}/js/backgroundsize.min.htc');

	top: 0;
	height: 100%;
	width: 100%;
}