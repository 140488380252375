@import (reference) '../core/vars';

.lt-ie9 .hidden-desktop {
	display: none;
}

@media @mobile {
	.hidden-mobile { display: none !important; }
}

@media @min-tablet {
	.hidden-desktop { display: none !important; }
}


@media (min-width: 992px) {
	.hidden-min-laptop { 
		display: none !important; 
	}
}

@media (max-width: 991px) {
	.hidden-max-laptop { 
		display: none !important; 
	}
}


