@import (reference) '../../core/colors';
@import (reference) '../../core/vars';

.box {
	position: relative;
	padding: 1em;

	.box--link {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		z-index: 1;
	}

	.box--banner {
		margin: -1em -1em 0 -1em;
	}

	.box--image {
		margin: -1em;
		padding-top: 100%;

		@media @mobile {
			padding-top: 50%;
		}

		@media @mini-mobile {
			padding-top: 100%;
		}

	}

	.box--image.nosquare {
		margin: -1em;
		padding-top: 61%;

		@media @mobile {
			padding-top: 50%;
		}

		@media @mini-mobile {
			padding-top: 61%;
		}

	}

	.box--text {
		position: absolute;
		top: auto; right: 0; bottom: 0; left: 0;
		opacity: 0.85;
		padding: 1em;
	}

	.box--input {
		position: relative;

		.gfx {
			position: absolute;
			right: 1em;
		}

		input {
			background: @marine-light;
			border-color: @marine-light;

			&:focus {
				border-color: white;
			}
		}
	}
}