@import (reference) '../../../core/vars';
@import (reference) '../../../core/header';

// Color everything
@import 'flat-menu-styles';

@media @desktop {
	@import 'flat-menu-desktop';
}
@media @mobile {
	@import 'flat-menu-mobile';
}