.big-menu {

	// First, second and third level
	.level {
		width: 100%;
		left: 0;

		.hover {
			.level {			
				opacity: 1;
				transform: scale(1) translate(0, 0);
			}
		}

		.visible {
			.level {
				visibility: visible;
			}
		}
	}

	li {
		&.active {
			a {
				text-decoration: underline;
			}
		}
	}
	
	// Target specific levels
	.default > ul > li{

		// Only second level
		> .level {
			position: absolute;
			visibility: hidden;
			opacity: 0;

			// No legacy styles needed, on IE < 10 the
			// .levels just snap in and pop out without
			// transition
			transform: scale(0.95) translate(0, 10%);
			transition: transform 300ms, opacity 300ms;

			// Account for small margin between first level link
			// and second level. This is to prevent flickering of
			// second level when mouse is over inactive area between
			// the two containers.
			margin-top: -1px;
			z-index: 2;
		}
	}

	.level2 {
		// Repair margin-top from invisible back link
		> ul > li:first-child + li {
			margin-top: 0;
		}
	}

	.level .level3 {
		transform: none;
		opacity: 1;

		// Always show third menu on desktop,
		// even if it is closed on mobile
		height: auto !important;
	}
	
	// Don't show mobile backlinks on desktop
	.back {
		display: none;
	}
}

// Ensure big menu stays closed if an
// element within has visibility: visible;
// and the user hovers over this element.
// Otherwise, such elements cause the level 1
// toggler to get an hover event and the menu
// shows up.
.big-menu--custom-content {
	display: none;

	.visible & {
		display: block;
	}
}