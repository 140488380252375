@import (reference) '../../core/vars';

.news--controls {
	&:extend(.clearfix all);

	> ul {
		list-style: none;
	}

	.news--prev {
		float: left;
	}

	.news--next {
		float: right;
	}

	.news--back {
		overflow: hidden;
		text-align: center;

		a {
			@media @max-tablet {
				.gfx(tiles);

				&:before {
					position: relative;
					padding: 0;
					text-align: center;
				}

				span {
					display: none;
				}
			}
		}
	}

	ul {
		padding-left: 0;
	}

	li {
		&:before {
			content: none;
		}

		.link {
			@media @mobile {
				padding: 0 !important;

				span {
					display: none;
				}
				
				&:before, &:after {
					position: relative;
					width: 48px;
					height: 48px;
					line-height: 48px;
					padding: 0;
					text-align: center;
					.branded-background(true);
				}
			}
		}
	}
}