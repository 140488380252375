@import (reference) '../../../core/vars';
@import (reference) '../../../core/colors';

#header {
	background: white;
}

.push-menu > .level {
	margin-left: -15px;

	a {
		text-decoration: none;
	}

	& > ul {
		.nav-ul(true);

		& > li {
			& > a {
				display: block;
				font-size: 17px;
				padding: 0.5em 1em;

				// Border bottom for active first level links
				position: relative;

				&:before, &:after {
					content: '';
					position: absolute;
					bottom: 0;
					top: auto;
					width: 0;
					height: 2px;
					transition: width 300ms;

					.branded-background();
				}
				&:before { left: 50%; }
				&:after { right: 50%; }

				&:hover:before, &:hover:after {
					width: 50%;
				}
			}

			// Activate border bottom
			&.hover > a,
			&.active > a {
				&:before, &:after {
					width: 50%;
				}
			}

			&:first-child{
				padding-left: 15px;

				& > a {
					padding-left: 0;
				}
			}
		}
		
	}
}

.push-menu .level {
	ul {
		.nav-ul();
	}
	
	// All further levels
	.level {

		li {
			transition: background 300ms;
			.branded-background(true, true);
		}

		// Deeper link only
		.deeper {
			.gfx(triangle-thin-right, after);
		}

		a, span {
			display: block;
			width: 250px;
			padding: 0.5em 3em 0.5em 1em;
			border-bottom: 1px solid white;

			.marine & { border-bottom-color: tint(@marine, 50%); }
			.red & { border-bottom-color: tint(@red, 50%); }
			.green & { border-bottom-color: tint(@green, 50%); }
			.purple & { border-bottom-color: tint(@purple, 50%); }
			.anthracite & { border-bottom-color: tint(@anthracite, 50%); }
			.orange & { border-bottom-color: darken(@orange, 10%); }
			.sky & { border-bottom-color: tint(@sky, 50%); }

			&.back {
				display: none;
			}			

			&.deeper:after,
			& .deeper {
				position: absolute;
				top: 0;
				right: 0;
				padding: 0.5em 1em;
				height: 100%;
				width: auto;
				background: transparent;
				border-left: solid 1px rgba(255, 255, 255, 0.3);

				.orange & { 
					border-left-color: rgba(0, 0, 0, 0.2);
				}
			}
	
			// Direct link with arrow
			& .deeper {
				&:after {
					position: relative;
					padding: 0;
					border-left: none;
				}
			}
		}
	}
}