@import (reference) '../../core/vars';

.filter--search {
	position: relative;
	float: right;
	margin-top: 13px;

	@media @mobile {
		float: none;
		margin-top: 0;
	}

	input {
		width: auto;
		border-top: none;
		border-right: none;
		border-left: none;
		padding-right: 1.5em;
		padding-left: 0;

		@media @mobile {
			width: 100%;
			margin-bottom: 1em;
		}
	}

	.gfx {
		position: absolute;
		right: 0;
		padding: 0.5em 0;
	}
}