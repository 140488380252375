.video-js {
	width: 100% !important; // For IE9
	padding-top: 56.25%; // 16:9
	font-size: @font-size;
}

.vjs-default-skin {
	.vjs-control-bar {
		font-size: 10px;
	}

	.vjs-big-play-button {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50px;
		height: 50px;
		transform: translate(-50%,-50%);
		border-radius: 100%;
		box-shadow: none;
		background: transparent;
		border: 1px solid white;
		font-size: 1em;
		z-index: 1;

		&:before {
			font-size: 30px;
			line-height: 50px;
			position: static;
			color: white;
			text-shadow: none;
		}
	}

	&:hover .vjs-big-play-button {
		background: none;
		box-shadow: none;
	}

	.vjs-play-progress
	, .vjs-volume-level {
		background: @red;
	}

	.vjs-seek-handle
	, .vjs-volume-handle {
		&:before {
			content: none;
		}
	}
}