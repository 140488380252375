@import (reference) '../../../core/mixins';

.csstransforms3d {
	.push-menu, .flat-menu, .big-menu {
		.level {
			transition: transform 500ms;
			transform: translate3d(-100%, 0, 0);

			&.in, &.default, &.in > ul > li > a {
				transform: translate3d(0, 0, 0);
			}
		}

		li {
			// Output: li:nth-child(x)>a {transition-delay: x*50;}
			.stagger(50, 10, ~'>a');
		}

		a:not(.back){
			display: block;
			transition: transform 500ms;
			transform: translate3d(-100%, 0, 0);
		}
	}

	// Hack for first level staggering, it depends on 
	// offcanvas being open or closed
	.push-menu, .flat-menu, .big-menu {
		.level {
			&.default > ul > li > a {
				transform: translate3d(-100%, 0, 0);
			}
		}
	}
	.off .push-menu .level.default > ul > li > a,
	.off .flat-menu .level.default > ul > li > a,
	.off .big-menu .level.default > ul > li > a {
		transform: translate3d(0,0,0);
	}
}