@import (reference) '../core/mixins';
@import (reference) '../core/colors';

.swap-colors {
	margin-left: 0.5em;
	height: 21px;
	padding-top: 3px;

	&:extend(.clearfix all);

	> div {
		width: 15px;
		height: 15px;
		margin: 0 2px;
		cursor: pointer;
		float: left;

		&.marine { background: @marine; }
		&.orange { background: @orange; }
		&.anthracite { background: @anthracite; }
		&.green { background: @green; }
		&.red { background: @red; }
		&.purple { background: @purple; }
		&.sky { background: @sky;}
		&.ami { background: @ami;}
		&.agef { background: @agef;}
		&.baurecht { background: @baurecht;}
		&.aref { background: @aref;}
		&.fmentoring { background: @fmentoring;}
		&.regard { background: @regard;}
	}
}