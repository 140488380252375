@not_print: ~'screen,handheld,projection,tv,tty';

tr {
	border-bottom: 1px solid @grey-dark;

	&:first-child {
		border-top: 1px solid @grey-dark;
	}

	@media @desktop, print {
		.vertical & {
			border-bottom: none;
			border-top: none;
		}
	}
}

.lt-ie9 & {
	table.vertical tr {
		border-bottom: none;
		border-top: none;
	}
}

thead {
	@media @mobile {
		display: none;
	}

	@media print {
		display: table-header-group;
	}
}

tr {
	@media @mobile {
		display: block;
		
		&:before {
			content: attr(data-th);
			display: block;
			font-family: @semibold;
			padding: 10px;
		}

		td {
			display: block;
		}
	}

	@media print {
		display: table-row;

		&:before {
			content: none;
		}

		td {
			display: table-cell;
		}
	}
}

th {
	font-weight: normal;
	font-family: @semibold;
	text-align: left;
}

td, th {
	padding: 10px;

	.vertical & {
		border-left: 1px solid @grey-dark;

		&:first-child {
			border-left: none;
		}

		@media @mobile {
			border-left: none;
			border-right: none;
		}

		@media print {
			border-left: 1px solid @grey-dark;
		}
	}
}