@import (reference) '../../core/colors';
@import (reference) '../../core/vars';

.dropdown--toggler {
	position: relative;
	cursor: pointer;

	&[data-accordion-state^='open'] .content-teaser--inner {
		&:before, &:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			height: 0;
			width: 0;
			border: 0 solid transparent;
			border-width: 9px 11px;
			margin-left: -11px;
			zoom: 1;
		}

		&:before {
			border-top-color: @grey;
		}

		&:after {
			margin-top: -2px;
			border-top-color: white;
		}
	}
}