
.no-csstransforms3d {
	.push-menu, .flat-menu, .big-menu {
		.level {
			transition: right 1000ms;
			right: 100%;

			&.in, &.default {
				right: 0;
			}
		}
	}
}

.no-csstransitions {
	.push-menu, .flat-menu, .big-menu {
		.level {
			&:before {
				visibility: hidden;
				display: none;
			}
		}
	}
}