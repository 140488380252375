@import (reference) '../../core/vars';

.banner--image {
	position: relative;
}

.banner--news {
	.banner--background-image {
		padding-top: 50%;   // only banner news
	}
}

.banner--background-image {
	position: relative;
	padding-top: 33%;   // all banner
	
	@media @mobile {
		padding-top: 44%;
	}
	
	@media @mini-mobile {
		padding-top: 55%;
	}

	.slider & {
		padding-top: 0;
	}

	&.background-size-cover {
		height: 550px;
		padding-top: 0;

		@media @max-desktop {
			height: 500px;
		}

		@media @max-laptop {
			height: 450px;
		}

		@media @max-tablet {
			height: 375px;
		}

		@media @mobile {
			height: 300px;
		}
	}
}