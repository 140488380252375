@import (reference) '../../../core/header';
@import (reference) '../../../core/vars';
@import (reference) '../../../core/colors';

.sub-menu--spacer {
	height: 55px;
}

.sub-menu--content{

	-webkit-overflow-scrolling: touch;

	& > * {
		// According to following link, this fixes some issues
		// on IOS with overflow-scrolling
		// http://stackoverflow.com/questions/7808110/css3-property-webkit-overflow-scrollingtouch-error
		-webkit-transform: translateZ(0px);
	}

}

.sub-menu {

	nav > div {
		position: relative;
	}

	// Legacy version of pusher needs position relative/absolute/fixed
	// (relative is used) and because of legacy clause, the selector is
	// too high to be overwritten by this single class selector.
	position: fixed !important;
	top: 55px;
	left: 0;
	width: 100%;
	padding: 0;
	background: @marine;
	z-index: 2; // Beneath the fixed header

	& + div {
		// Correct inner margin on col-xy-.. from .inner on row
		margin-top: 0 !important;
	}

	// This is IE, please talk loud and clearly
	// and make him move this damn sub-menu
	.msie & {
		transition: transform 500ms;
	}
	.msie .off & {
		transform: translate3d(100%,0,0);
	}
	.msie.no-csstransforms3d .off & {
		left: 100%;
	}

	h4 {
		background: fade(white, 10%);
		color: white;

		a {
			padding: 1em;
			display: block;

			.gfx(close, after);
			&:after {
				position: absolute;
				right: 1em;
				transition: transform 300ms;
				transform: rotate(45deg);
			}
			&[data-expander-state^='open'] {
				&:after {
					transform: rotate(180deg);
				}
			}
		}
	}

	ul {
		padding-left: 0;
		color: white;
	}

	li {
		border-top: 1px solid fade(white, 10%);
		&:before {
			content: none;
		}
	}

	a {
		display: block;
		padding: 1em;
		transition: background 300ms;

		&:hover {
			background: fade(black, 10%);
		}

		&.deeper {
			position: relative;

			i {
				position: absolute;
				right: 0;
				top: 0;
				padding: 1em;
				line-height: 21px;

				&:before {
					transition: transform 300ms;
				}
				
				&[data-expander-state^='open'] {
					&:before {
						transform: scaleY(-1);
					}
				}
			}
		}
	}
	
	.level {
		li {
			//padding-left: 1.5em;
			a {
				padding-left: 1.5em;
			}
			.level{
				li{
					a{
						padding-left: 2.5em;
					}
				}
			}
		}
	}

}
