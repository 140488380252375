@import (reference) '../../../core/vars';
@import (reference) '../../../core/mixins';

// Import styles for desktop
@media @desktop {
	@import 'meta-menu-styles-desktop';
}
// Mobile styles
@media @mobile {
	@import 'meta-menu-styles-mobile';
}
// IE 8 styles
/* .lt-ie9 {
	@import 'meta-menu-styles-desktop';
} */

// General styles
.meta-menu {
	float: right;
}

.meta-menu--content {
	position: absolute;
	left: 0;
	width: 100%;

	// Reset square
	text-align: left;
	line-height: @line-height;

	ul {
		.nav-ul();
	}

	.first-level {
		a {
			display: block;

			&.gfx:before {
				padding-right: 1em;
			}
		}
	}
}

// Display close icon if accordion is opening or open
.meta-menu--toggler {
	&[data-accordion-state^='open'] {
		.gfx {
			.gfx(close);
		}
	}
}