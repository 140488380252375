@fontpath: '@{assets}/fonts';

.font(OpenSans-Bold-webfont);
.font(OpenSans-Light-webfont);
.font(OpenSans-Regular-webfont);
.font(OpenSans-Semibold-webfont);

.font(@family, @weight: normal, @style: normal){
	@font-face {
	  font-family: @family;
	  src: url('@{fontpath}/@{family}.eot');
	  src: url('@{fontpath}/@{family}.eot#iefix') format('embedded-opentype'),
	       url('@{fontpath}/@{family}.woff') format('woff'),
	       url('@{fontpath}/@{family}.ttf') format('truetype'),
	       url('@{fontpath}/@{family}.svg') format('svg');
	  font-weight: @weight;
	  font-style: @style;
	}
}