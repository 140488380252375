@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

.agenda--outer {

	&:after {
		content: '';
		position: absolute;
		bottom: @gutter;
		left: @gutter;
		right: @gutter;
		top: @gutter*-1 - 1;
		border: 1px solid transparent;
		border-bottom-color: @grey-dark;

		@media @mobile {
			left: @gutter-mobile;
			right: @gutter-mobile;
			top: @gutter-mobile * -2 - 1;
		}
	}
	
	&[data-accordion-state^='open']:after {
		border-color: @grey-dark;
		content: ' '; // Help IE detect change
	}
}

.agenda--teaser {
	padding: 0 10px 30px 10px;

	[data-accordion-state^='open'] > & {
		&:before, &:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -11px;
			border-width: 9px 11px;
			border-style: solid;
			border-color: transparent;
		}

		&:before {
			margin-top: -15px;
			border-top-color: @grey-dark;
		}

		&:after {
			margin-top: -16px;
			border-top-color: white;
			z-index: 1;
		}
	}
}