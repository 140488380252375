@import (reference) '../../../core/mixins';
@import (reference) '../../../core/colors';
@import (reference) '../../../core/vars';

.fixed-header {
	.language-switch {
		float: right;

		ul {
			.nav-ul(true);
		}

		li {
			border-left: 1px solid @anthracite;
			&:first-child {
				border-left: none;
			}
		}

		a {
			display: block;
			padding: 0 0.5em;
		}
	}
}