@import (reference) '../../core/colors';
@import (reference) '../../core/mixins';

button,
.button,
input[type='button'],
input[type='reset'] {

	box-shadow: none;
	border: none;
	outline: none;
	
	display: block;
	padding: 0.5em 1em;
	
	// Make arrows bounce
	&.gfx {
		&:before {
			margin-right: 0.5em;
		}
		&.bounce:hover {
			&:before {
				.bounce-left();
			}
		}
		&.after {		
			&:after {
				margin-left: 0.5em;
			}

			&.bounce:hover {
				&:after {
					.bounce-right();
				}
			}
		}
	}

	&.full-width {
		width: 100%;
	}

	.button-group & {
		float: left;
		margin-left: 1em;

		&:first-child {
			margin-left: 0;
		}
	}
}

.button-group {
	.clearfix();
	
	button, .button, input[type='button'] {
		float: left;
		margin-left: 1em;

		&:first-child {
			margin-left: 0;
		}
	}
	&.right {
		button, .button, input[type='button'] {
			float: right;
			margin-right: 1em;
			margin-left: 0;

			&:first-child {
				margin-right: 0;
			}
		}
	}
}