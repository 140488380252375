@import (reference) '../../core/colors';
@import (reference) '../../core/mixins';

.radio-group {
	.inner(0.5em);

	&.error {
		&:after {
			display: block;
			content: attr(data-error-msg);
			color: @red;
			padding: 0 1.5em;
		}
	}
}

.radio {
	display: block;

	label {
		padding-left: 0; 
	}

	input[type="radio"] {
		position: absolute;
		height: 0;
		width: 0;
		opacity: 0;
	}

	input:checked + span:after {
		display: block;
	}

	span {
		position: relative;
		padding-left: 1.5em;
		cursor: pointer;

		&:before, &:after {
			position: absolute;
			content: '';
			left: 0;
		}
		&:before {
			height: 16px;
			width: 16px;
			border-radius: 100%;
			top: 0.2em;
			border: 1px solid @anthracite;

			.error & {
				border-color: @red;
			}
		}

		&:after {
			display: none;
			top: 6px;
			left: 3px;
			height: 10px;
			width: 10px;
			border-radius: 100%;
			background: @anthracite;
		}
	}
}