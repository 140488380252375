@import 'home-grid';
@import 'news-slider';

.home, .universitas {
	padding-top: 10px;
	
	.overlap {
		margin-top: -4em;

		@media @max-tablet {
			margin-top: 0;
		}
	}
}

.shadow {
	//box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.05);
	border-bottom: 1px solid @grey-dark;
}