@import (reference) '../core/vars';
@import (reference) '../core/colors';
@import (inline) '../../../bower_components/normalize.css/normalize.css';


* {
  box-sizing: border-box;
  -moz-box-sizing: border-box; //firefox 27 fix
}
*, *:before, *:after { 
	box-sizing: border-box; 
	-moz-box-sizing: border-box; //firefox 27 fix


}




html, body {
	height: 100%;
}

body {
	color: @anthracite;
	font-family: @regular;
	font-size: @font-size;
	line-height: @line-height;
}

//a moved to links.less


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 0;
	font-weight: normal;
	line-height: @line-height;
}

// Only used to wrap the logo
h1 { }

h2, .h2 {
	font-family: @semibold;
	font-size: 35px;

	@media @mobile {
		font-size: 26px;
	}
}

// Specific to Universitas
h2.large{font-size: 3.22em; line-height: 1em;}
h2.large > small{font-size: 0.61em; line-height: 0.61em; color:@anthracite;}
@media @mobile {
	h2.large{font-size: 2.22em; line-height: 1em;}
	h2.large > small{font-size: 0.61em; line-height: 0.61em; color:@anthracite;}
}

h3, .h3 {
	font-family: @semibold;
	font-size: 22px;
}

h4, .h4 {
	font-family: @semibold;
	font-size: 18px;
}

h5, .h5 {
	font-family: @semibold;
	font-size: 17px;
}

h6, .h6 {
	font-family: @regular;
	font-size: 16px;
}

hr {
	margin: 0;
	border: none;
	border-top: 1px solid @grey-dark;
}

p {
	margin: 0;

	strong, b { font-family: @bold; font-weight: normal; }
}

ul, ol {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 1.5em;
}

b, strong {
	font-weight: normal;
	font-family: @bold;
}

span.light {
	color: @anthracite-light;
}
span.voidempty:empty{
	padding-right:0em;
}
span.voidempty{
	padding-right:1.61em;
}

.content {
	ol {
		list-style: none;

		> li {
			position: relative;
			counter-increment: ol;

			&:before {
				position: absolute;
				left: -1.5em;
				content: counter(ol) '.';
			}
		}
	}
}

img {
	display: block;
	max-width: 100%;
}

figure {
	margin: 0;
}

figcaption {
	margin-top: 0.25em;
	font-size: 13px;
}

iframe {
	max-width: 100% !important;
}

// More table styles under modules/content/tables.less
table {
	width: 100%;
}

cite {
	display: block;
	font-style: normal;
	border-top: 1px solid @grey-dark;
	padding-top: 0.5em;

	blockquote & {
		display: inline;
		border-top: none;
		padding-top: 0;
	}
}

blockquote {
	border-left: 4px solid @grey-light;

	&.pull-right {
		border-right: 4px solid @grey-light;
	}
}

fieldset {
	border: none;
}