#footer {
	> .container {
		padding-top: 1em;
		padding-bottom: 1em;
	}

	.footer-menu {
		display: none;
	}

	.footer-menu--contact {
		ul {
			margin-top: 2em;
		}

		li {
			margin-top: 1em;
		}
	}

	.footer-menu--meta {
		.footer--copyright {
			float: none;
			span { display: none; }
		}
		.footer--social-links {
			float: none;
		}
	}
}