@import (reference) '../../../core/vars';
.search {
	float: right;
	margin-left: 7px;
	font-size: 15px;
}

.search--toggler {
	background: @grey;
	transition: background 300ms;

	&:hover {
		background: darken(@grey, 5%);
	}

	&[data-accordion-state^='open'] {
		i {
			.gfx(close);
		}
	}
}

.search--input {
	position: absolute;
	top: 31px;
	width: 100%;
	left: 0;
	background: @grey;

	> .container {
		position: relative;
		padding-top: 1em;
		padding-bottom: 1em;
		.gfx(search);

		&:before {
			position: absolute;
			//left: 0;
		}
	}

	span.sd>label.btn-default{
		opacity:0.5;
	}

	input[type="text"] {
		padding: 0 0 0.5em 1.5em;
		background: transparent;
		border: none;
		border-bottom: 1px solid @anthracite;
	}

	//input[name="searchdomain"]:checked + label{
	//	background-color:@marine;
	//	color: @white;
	//}
}