@import (reference) '../../../core/vars';
@import (reference) '../../../core/colors';

.meta-menu--toggler {
	border-left: 1px solid @grey;
	background: white;

	&:hover {
		background: darken(white, 5%);
	}
	
	.gfx {
		font-size: 1em;
	}
}

.meta-menu--content {
	position: relative;
	background: @grey-light;

	> .container {
		padding: 0;
		-webkit-overflow-scrolling: touch;
		
		& > * {
			// According to following link, this fixes some issues
			// on IOS with overflow-scrolling
			// http://stackoverflow.com/questions/7808110/css3-property-webkit-overflow-scrollingtouch-error
			-webkit-transform: translateZ(0px);
		}
	}

	.first-level {
		a {
			padding: 16.5px;
		}

		> a {
			background: white;
			border-bottom: 1px solid @grey-light;
			font-family: @light;
			font-size: 17px;
			text-decoration: none;

			.gfx(triangle-thin-down, after);

			&:after {
				float: right;
				transition: transform 300ms;
			}

			&[data-accordion-state^='open'] {
				&:after {
					transform: scaleY(-1);
				}
			}
		}
		
		ul {
			padding-left: 20px;
		}
	}
	
	.meta-menu--contact {
		a {
			position: relative;
			display: block;
			padding-left: 16.5px;

			&:before {
				position: absolute;
				left: 0;
			}
		}
	}
}
