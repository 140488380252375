.no-flexbox.no-flexboxlegacy {
	.breadcrumbs--content {
		ul {
			&:extend(.clearfix all);
		}

		li {
			float: left;
		}
	}

	.breadcrumbs--mobile.shortened {
		li {
			width: 20%;
			text-align: center;

			&.gfx {
				width: 6%;
			}
		}
	}
}