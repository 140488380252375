@import (reference) '../../../core/vars';
@import (reference) '../../../core/mixins';

.breadcrumbs {
	overflow: hidden;
	padding-left: 0;
}

.breadcrumbs--content {
	height: auto !important;

	ul {
		// Display ellipsis
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		.nav-ul();
	}

	li {
		// display: inline for overflow ellipsis to work
		display: inline;
		float: none;

		&.gfx {
			&:before {
				padding: 1px 0.5em 0;
				font-size: 0.8em;
			}
		}
	}
}

.breadcrumbs--mobile {

	// Hide long versions of upper levels
	.long { display: none; }

	// Show current level
	.desktop-expanded {
		.short { display: none; }
		.long {
			// Inline to not break ellipsis functionality
			display: inline;
			font-family: @bold;
		}
	
		// Hide deeper levels
		& ~ li {
			display: none;
		}
	}
}