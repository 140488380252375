@import (reference) '../../core/colors';
@import (reference) '../../core/mixins';

.checkbox {
	display: block;

	label {
		padding-left: 0; 
	}

	input[type="checkbox"] {
		position: absolute;
		height: 0;
		width: 0;
		opacity: 0;
	}
	input:checked + span {
		.gfx(check, after);
	}

	span {
		display: block;
		position: relative;
		padding-left: 1.5em;
		cursor: pointer;

		&:before, &:after {
			position: absolute;
			content: '';
			left: 0;
			height: 1em;
			width: 1em;
		}
		&:before {
			top: 0.2em;
			border: 1px solid @anthracite;
		}
		&:after {
			top: 0;
		}
	}

	&.error {
		span:before {
			border-color: @red;
		}
		&:after {
			display: block;
			content: attr(data-error-msg);
			color: @red;
			padding: 0 1.5em;
		}
	}
}