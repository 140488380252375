@import (reference) '../../core/vars';
@import (reference) '../../core/colors';

#main {
	background: @ochre-light;

	// Estimate header height, will be overwritten
	// with correct value once javascript is loaded.
	// This is set to minimize hidden content while
	// the page is loading.
	padding-top: 218px;
	padding-bottom: 2em;

	@media @mobile {
		padding-top: 57px;
		padding-bottom: 15px;
	}
}

#main.universitas{
	background: @universitas-bg;
}
#main.universitas-article{
	background: @white;
	.content{
		border-bottom: none!important;
	}
}

.content {
	padding: 2em;
	background: white;
	//box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.05);
	border-bottom: 1px solid @grey-dark;

	@media @desktop {
		padding: 2em;
	}

	@media @mobile {
		margin-top: 1em;
		padding: 1em;
	}
}

.strip {
	margin-top : 1.6em;
	margin-bottom : 0;
	padding-top : 1.61em;
	padding-bottom : 1.61em;
	background-color: @white;
}
.reset-main{
	@media @desktop {
		margin-bottom: -2em;
	}
	@media @mobile {
		margin-bottom: -1em;
	}
}

.universitas {
	@media @desktop {

	}
}

footer#partners {
	padding: 1.61em;
}