.row.inner > div { 
	margin-top: 0;
	
	@media @max-laptop {
		&[class|="col-lg"] {
			margin-top: 30px;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	@media @max-tablet {
		&[class|="col-md"] {
			margin-top: 30px;

			&:first-child {
				margin-top: 0;
			}
		}
	}
	
	@media @max-mobile {
		&[class|="col-sm"] {
			margin-top: 30px;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	@media @mobile {
		&[class|="col"] {
			margin-top: 15px;
		}
	}
 }