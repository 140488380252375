@import (reference) '../../core/colors';
@import (reference) '../../core/vars';

.agenda--shortlist {
	list-style: none;
	padding-left: 0;

	li {
		padding: 0.75em 0;
		border-bottom: 1px solid @ochre;
		&:extend(.clearfix all);
		&:first-child {
			padding-top: 0;
		}
	}

	a {
		span {
			display: block;
		}
	}
}