@import (reference) '../../../core/vars';
@import (reference) '../../../core/header';
@import (reference) '../../../core/colors';

.service-menu {
	height: @mobile-header-height;
	&:extend(.clearfix all);

	.close-offcanvas {
		font-size: 17px;
		float: right;
		border-bottom: 1px solid rgba(255,255,255,0.1);

		.gfx(close);
	}
}