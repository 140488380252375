@import (reference) '../../core/colors';
@import (reference) '../../core/vars';

.clndr {
	border-bottom: 1px solid @grey-dark;
}

.clndr--parent {
	margin-left: 30px;
}

.clndr--controls {
	background: @marine;
	color: white;
	&:extend(.clearfix all);

	.clndr--prev, .clndr--next {
		padding: 0.5em;
		cursor: pointer;

		&.inactive {
			opacity: 0.5;
			cursor: default;
		}
	}

	.clndr--prev {
		float: left;
	}

	.clndr--next {
		float: right;
	}

	.clndr--month {
		padding: 0.5em;
		overflow: hidden;
		text-align: center;
	}
}

.clndr--dow {
	background: @grey-light;

	&:extend(.clearfix all);

	.clndr--day-header {
		float: left;
		width: 100%/7;
		text-align: center;
		padding: 0.25em 0;
	}

	.no-dow & {
		display: none;
	}
}

.clndr--days {
	&:extend(.clearfix all);

	.day {
		position: relative;
		float: left;
		width: 100%/7;
		text-align: center;
		padding-top: 100%/7;
		cursor: pointer;

		@media @min-mobile and @max-tablet {
			padding-top: 100%/14;
		}

		&.inactive {
			color: @grey-dark;
			cursor: default;
		}

		&.adjacent-month, &.next-month {
			visibility: hidden;
		}

		&.today {
			color: @red;
		}

		&.selected {
			background: @grey;
		}

		> span {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;

			>span {
				display: table;
				height: 100%;
				width: 100%;

				>span {
					display: table-cell;
					vertical-align: middle;
					height: 100%;
				}
			}
		}
	}
}