@import (reference) '../../core/colors';

.slick-prev, .slick-next {
	position: absolute;
	top: 50%;
	display: block;
	padding: 0.5em;
	margin: -15px 0 0 0;
	line-height: 1em;
	background: @marine;
	color: white;

	border: none;
	box-shadow: none;
	outline: none;
	
	// Show arrows only on hover or if touch is enabled
	opacity: 0;
	transition: opacity 300ms;

	.touch &, .slider:hover & {
		opacity: 1;
	}

	.no-arrows & {
		// Overpower slicks inline style (display: inline-block)
		display: none !important;
	}
}

.slick-prev {
	left: 1em;
	.gfx(triangle-left);
}

.slick-next {
	right: 1em;
	.gfx(triangle-right);
}